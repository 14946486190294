import React, { Key, ReactElement } from "react";
import { CollectionBase, CollectionElement, Node, PartialNode } from "../types";

interface CollectionBuilderState {
  renderer?: (value: any) => ReactElement;
}

export class CollectionBuilder<T extends object> {
  private context?: unknown;
  private cache: WeakMap<T, Node<T>> = new WeakMap();

  build(props: CollectionBase<T>, context?: unknown) {
    this.context = context;
    return iterable(() => this.iterateCollection(props));
  }

  private *iterateCollection(props: CollectionBase<T>) {
    const { children, items } = props;

    if (typeof children === "function") {
      if (!items) {
        throw new Error("props.children was a function but props.items is missing");
      }

      for (const item of props.items || []) {
        yield* this.getFullNode(
          {
            value: item,
          },
          { renderer: children }
        );
      }
    } else {
      const items: Array<CollectionElement<T>> = [];
      React.Children.forEach(children, (child) => {
        items.push(child);
      });

      let index = 0;
      for (const item of items) {
        const nodes = this.getFullNode(
          {
            element: item,
            index: index,
          },
          {}
        );

        for (const node of nodes) {
          index++;
          yield node;
        }
      }
    }
  }

  private getKey(
    item: CollectionElement<T>,
    partialNode: PartialNode<T>,
    _: CollectionBuilderState,
    parentKey?: Key
  ): Key {
    if (item.key != null) {
      return item.key;
    }

    if (partialNode.type === "cell" && partialNode.key != null) {
      return `${parentKey}${partialNode.key}`;
    }

    const v = partialNode.value as any;
    if (v != null) {
      const key = v.key ?? v.id;
      if (key == null) {
        throw new Error("No key found for item");
      }

      return key;
    }

    return parentKey ? `${parentKey}.${partialNode.index}` : `$.${partialNode.index}`;
  }

  private getChildState(state: CollectionBuilderState, partialNode: PartialNode<T>) {
    return {
      renderer: partialNode.renderer || state.renderer,
    };
  }

  private *getFullNode(
    partialNode: PartialNode<T>,
    state: CollectionBuilderState,
    parentKey?: Key,
    parentNode?: Node<T>
  ): Generator<Node<T>> {
    // If there's a value instead of an element on the node, and a parent renderer function is available,
    // use it to render an element for the value.
    let element = partialNode.element;
    if (!element && partialNode.value && state && state.renderer) {
      const cached = this.cache.get(partialNode.value);
      if (cached && (!cached.shouldInvalidate || !cached.shouldInvalidate(this.context))) {
        cached.index = partialNode.index;
        cached.parentKey = parentNode ? parentNode.key : undefined;
        yield cached;
        return;
      }

      element = state.renderer(partialNode.value);
    }

    // If there's an element with a getCollectionNode function on its type, then it's a supported component.
    // Call this function to get a partial node, and recursively build a full node from there.
    if (React.isValidElement(element)) {
      const type = element.type as any;
      if (typeof type !== "function" || typeof type.getCollectionNode !== "function") {
        const name = typeof element.type === "function" ? element.type.name : element.type;
        throw new Error(`Unknown element <${name}> in collection.`);
      }

      const childNodes = type.getCollectionNode(element.props, this.context) as Generator<
        PartialNode<T>,
        void,
        Array<Node<T>>
      >;
      let index = partialNode.index || 0;
      let result = childNodes.next();
      while (!result.done && result.value) {
        const childNode = result.value;

        partialNode.index = index;

        let nodeKey: Key | null | undefined = childNode.key;
        if (!nodeKey) {
          nodeKey = childNode.element
            ? null
            : this.getKey(element as CollectionElement<T>, partialNode, state, parentKey);
        }

        const nodes = this.getFullNode(
          {
            ...childNode,
            key: nodeKey!,
            index,
            wrapper: compose(partialNode.wrapper, childNode.wrapper) as any,
          },
          this.getChildState(state, childNode),
          parentKey ? `${parentKey}${element.key}` : element.key!,
          parentNode
        );

        const children = [...nodes];
        for (const node of children) {
          // Cache the node based on its value
          (node as Node<any>).value = childNode.value || partialNode.value;
          if (node.value) {
            this.cache.set(node.value, node);
          }

          // The partial node may have specified a type for the child in order to specify a constraint.
          // Verify that the full node that was built recursively matches this type.
          if (partialNode.type && node.type !== partialNode.type) {
            throw new Error(
              `Unsupported type <${capitalize(node.type)}> in <${capitalize(
                parentNode!.type
              )}>. Only <${capitalize(partialNode.type)}> is supported.`
            );
          }

          index++;
          yield node;
        }

        result = childNodes.next(children);
      }

      return;
    }

    // Ignore invalid elements
    if (partialNode.key == null) {
      return;
    }

    // Create full node
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const builder = this;
    const node: Node<T> = {
      "type": partialNode.type!,
      "props": partialNode.props,
      "key": partialNode.key,
      "parentKey": parentNode ? parentNode.key : undefined,
      "value": partialNode.value!,
      "level": parentNode ? parentNode.level + 1 : 0,
      "index": partialNode.index,
      "rendered": partialNode.rendered,
      "textValue": partialNode.textValue!,
      "aria-label": partialNode["aria-label"],
      "wrapper": partialNode.wrapper,
      "shouldInvalidate": partialNode.shouldInvalidate,
      "hasChildNodes": partialNode.hasChildNodes!,
      "childNodes": iterable(function* () {
        if (!partialNode.hasChildNodes) {
          return;
        }

        let index = 0;
        for (const child of partialNode?.childNodes?.() || []) {
          // Ensure child keys are globally unique by prepending the parent node's key
          if (child.key != null) {
            child.key = `${node.key}${child.key}`;
          }

          child.index = index;
          const nodes = builder.getFullNode(
            child,
            builder.getChildState(state, child),
            node.key,
            node
          );
          for (const node of nodes) {
            index++;
            yield node;
          }
        }
      }),
    };

    yield node;
  }
}

// Wraps an iterator function as an iterable object, and caches the results.
function iterable<T>(iterator: () => IterableIterator<Node<T>>): Iterable<Node<T>> {
  const cache: any[] = [];
  let iterable: IterableIterator<any>;
  return {
    *[Symbol.iterator]() {
      for (const item of cache) {
        yield item;
      }

      if (!iterable) {
        iterable = iterator();
      }

      for (const item of iterable) {
        cache.push(item);
        yield item;
      }
    },
  };
}

type Wrapper = (element: ReactElement) => ReactElement;
function compose(outer: Wrapper | void, inner: Wrapper | void): Wrapper | void {
  if (outer && inner) {
    return (element) => outer(inner(element));
  }

  if (outer) {
    return outer;
  }

  if (inner) {
    return inner;
  }
}

function capitalize(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}
