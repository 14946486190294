import React, { NamedExoticComponent } from "react";
import ContentLoader from "react-content-loader";
import { keyframes, css } from "ui/css";
export const TableRow = ({ style, className, height, animate }: any) => {
  const random = Math.random() * (1 - 0.6) + 0.6;
  const common = {
    rx: 4,
    height: 12,
  };
  return (
    <ContentLoader
      height={height}
      width={1372}
      animate={animate}
      className={className}
      primaryColor="#cbcbcb"
      secondaryColor="#a0b9ed"
      style={{ height: height, width: "100%", ...style, viewBox: "-10 -5 1350 40" }}
    >
      <rect x="24" y="13.4286" width={473 * random} {...common} />
      <rect x="585" y="13.4286" width={370 * random} {...common} />
      <rect x="1190 " y="13.4286" width={160 * random} {...common} />
    </ContentLoader>
  );
};

const fadeAnimation = keyframes({
  "0%": { opacity: 0.1 },
  "100%": { opacity: 1 },
});

const animationClassname = css({
  animation: `${fadeAnimation} 4s ease-out`,
  animationFillMode: "forwards",
  opacity: 0.01,
  boxShadow: "2px 1px 1px 0px hsla(0, 0%, 92%, 1)",
});

const Table: NamedExoticComponent<any> = React.memo(
  function Table({ count = 10, style, height = 35, animate = true }) {
    return (
      <>
        {Array(count)
          .fill("")
          .map((_, i) => (
            <TableRow
              key={i}
              height={height}
              animate={animate}
              className={animate ? animationClassname().className : ""}
              style={{
                animationDelay: Math.max(i / 3 - 0.5, 0) + "s",
                ...style,
              }}
            />
          ))}
      </>
    );
  },
  () => true
);

export const TableLoader = Table;
