import React, { useEffect } from "react";
import { reduce, upperFirst } from "lodash";
import { Field, Form, FormRenderProps } from "react-final-form";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Button, FormHelperText, LinearProgress } from "@mui/material";
import {
  getValidator,
  getNotEmptyValidatorWithLabel,
  validateEmail,
  parseEmail,
} from "modules/users/utils";
import { Stack } from "libs/layouts";
import { DividerWithText, Loader } from "ui";
import { useAvailableAuthProviders } from "../useAuthProviders";
import {
  EmailField,
  PasswordField,
  ProviderButton,
  ProviderButtonProps,
  TwoFactorPhoneAuthForm,
} from "../atoms";
import { messages } from "../messages";
import { useLogin } from "../apiHooks";
import { SetupMFA } from "./SetupMFA";
import { useCaptcha } from "../loginHooks";

export interface ILoginFormProps {}

const initialValues = {
  email: "",
  password: "",
};

const validate = (values: any) => {
  return reduce(
    values,
    (errors: any, value, field) => {
      if (!value) {
        errors[field] = messages.validation.required;
      }
      return errors;
    },
    {}
  );
};

const renderForm = ({ handleSubmit, submitting, error, submitError }: FormRenderProps) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="on">
      {submitting && <LinearProgress />}
      <Stack space={4}>
        {!submitting && error && <FormHelperText error>{error || submitError}</FormHelperText>}
        <Field
          name="email"
          component={EmailField as any}
          placeholder={messages.formFields.email}
          fullWidth
          parse={parseEmail}
          validate={validateEmail}
        />
        <Field
          name="password"
          component={PasswordField as any}
          placeholder={messages.formFields.password}
          fullWidth
        />
        <Stack space={2}>
          <Button fullWidth color="primary" variant="contained" type="submit" disabled={submitting}>
            {messages.logIn}
          </Button>
          <Link
            to="/forgot"
            style={{ textAlign: "center", color: "#818181", textDecoration: "none" }}
          >
            {messages.forgotPassword}
          </Link>
        </Stack>
      </Stack>
    </form>
  );
};

export function LoginForm() {
  const { mutateAsync: login, error, reset } = useLogin();
  const [captchaNode, verifier] = useCaptcha([], error?.type === "mfa");
  const { mutate: sendCode, error: sendCodeError } = useMutation({
    mutationFn: () => {
      return error?.type === "mfa" ? error.sendCode(verifier.current) : Promise.resolve();
    },
  });

  (window as any).loginInternal = () => {
    login({ type: "cloud", cloud: "google", params: { name: "google.com" } });
  };
  const { providers, isAllLoading, isSomeLoading, isPasswordAuthEnabled } =
    useAvailableAuthProviders();
  useEffect(() => {
    if (error?.type === "mfa") {
      setTimeout(() => {
        sendCode();
      }, 200);
    }
  }, [error]);
  if (error?.type === "mfa") {
    return (
      <Form
        validate={getValidator(
          getNotEmptyValidatorWithLabel("twoFactorCode", "Two factor code is required")
        )}
        onSubmit={(v) => {
          return error.verifyCode(v.twoFactorCode);
        }}
        render={(props) => (
          <>
            {captchaNode}
            <TwoFactorPhoneAuthForm
              {...props}
              error={props.error || (sendCodeError as any)?.message}
              onCancel={reset}
              resend={() => {
                sendCode();
              }}
            />
          </>
        )}
      />
    );
  }

  if (error?.type === "mfa-required") {
    return <SetupMFA onDone={error.finishAuth} />;
  }

  return (
    <Stack space={4}>
      {isAllLoading && <Loader loading />}
      {isSomeLoading && !isAllLoading && <LinearProgress />}
      <Stack space={2}>
        {providers.map((provider) => {
          return (
            <ProviderButton
              key={provider.id}
              type={provider.cloudType as ProviderButtonProps["type"]}
              label={`Log in with ${upperFirst(provider.name)}`}
              onClick={() =>
                login({ type: "cloud", cloud: provider.cloudType, params: { name: provider.id } })
              }
            />
          );
        })}
      </Stack>
      {isPasswordAuthEnabled && (
        <>
          {Boolean(providers?.length) && <DividerWithText>OR</DividerWithText>}
          <Form
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values) => login({ type: "local", values })}
            render={(values) => renderForm({ ...values, submitError: error?.message })}
          />
        </>
      )}
    </Stack>
  );
}
