import { isEqual, upperFirst } from "lodash";
import { useState } from "react";
import { useHistoryParam } from "shared";
import { recordKeys } from "libs/utils/helpers";
import { IConfig, SingleFilterState, useFiltersType } from "./useFilters.type";

function useParamWithCallback<T>(name: string, initialValue: T, keepInHistory = true, cb: any) {
  const [value, setValue] = useHistoryParam(
    name,
    initialValue instanceof Set ? [...initialValue] : initialValue,
    keepInHistory
  );
  return [
    initialValue instanceof Set ? new Set(value as any) : value,
    (v: T, preventOrderChange = false) => {
      const res = setValue(v instanceof Set ? [...v] : v);
      !preventOrderChange && cb(name);
      return res;
    },
  ] as const;
}

export const useFilters: useFiltersType = (config: IConfig) => {
  const [lastActive, setFiltersOrder] = useState("");
  config.queryKeyPrefix ??= "";
  const filters: Record<string, SingleFilterState> = {};
  for (const key in config.filters) {
    const filter = config.filters[key];

    const hookDefaultValue = config.initialValue?.[key] ?? filter.defaultValue;
    const [value, setValue] = useParamWithCallback(
      config.queryKeyPrefix + filter.queryKey,
      hookDefaultValue,
      true,
      () => {
        setFiltersOrder(key);
      }
    );

    filters[key] = {
      value: value,
      setValue: setValue,
      reset: () => setValue(filter.defaultValue),
      isDefault: isEqual(filter.defaultValue, value),
      isClearAll: typeof config.clearAllFilter !== "function" || config.clearAllFilter(key),
      isZero: Array.isArray(value)
        ? value.length === 0
        : value instanceof Set
        ? value.size === 0
        : !value,
      queryKey: filter.queryKey,
    };
  }
  const resetAll = () => {
    for (const k in filters) {
      if (filters[k].isClearAll) {
        filters[k].reset();
      }
    }
  };

  const outFilters = {} as Record<string, any>;
  for (const key in config.filters) {
    outFilters[key] = filters[key].value;
    outFilters[getResetFunctionName(key)] = filters[key].reset;
    outFilters[getSetterFunctionName(key)] = filters[key].setValue;
  }

  const numAppliedFilters = Object.values(filters).filter((el) => !el.isZero).length;

  return {
    ...outFilters,
    resetAll,
    getChipsFilterProps() {
      return { filters, resetAll, lastActive };
    },
    numAppliedFilters,
    hasFilters: numAppliedFilters > 0,
  } as any;
};

export function getSetterFunctionName(key: string) {
  return `set${upperFirst(key)}`;
}

export function getResetFunctionName(key: string) {
  return `reset${upperFirst(key)}`;
}

export function getIsFilterActiveFunction<
  ColumnField extends string,
  FilterKey extends string | undefined
>(
  appliedFilters: Record<string, SingleFilterState>,
  columnToFilterMap: Record<ColumnField, FilterKey>
) {
  return (name: ColumnField) => {
    return recordKeys(appliedFilters).some(
      (key) => key === columnToFilterMap[name] && !appliedFilters[key].isDefault
    );
  };
}
