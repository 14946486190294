import React, { ForwardedRef, forwardRef } from "react";
import { InputBase, InputBaseProps } from "../InputBase/InputBase";

export type InputTextProps = InputBaseProps;

export const InputText = forwardRef(function InputText(
  props: InputTextProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return <InputBase {...props} ref={ref} />;
});
