import React, { useEffect, useRef, useState } from "react";
import { throttle } from "lodash";
import { FiltersIcon } from "assets/icons";
import { Button, Popover, Typography } from "@mui/material";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { usePopover } from "libs/hooks";
import { subscribeEvent } from "libs/dom";
import { HStack, Stack } from "libs/layouts";
import { Loader, SearchInput, Box, space } from "ui";
import { HeaderCellProps } from "libs/css-grid-table/types";
import { DefaultThContent } from "libs/css-grid-table/ThContent";
import { CheckBox } from "modules/endpoint-sensors/atoms";

const useFetchNext = (
  container: React.RefObject<HTMLElement | null>,
  el: React.RefObject<HTMLElement>,
  distance: number,
  cb: any,
  enabled: boolean
) => {
  useEffect(() => {
    if (!container.current || !el.current || !enabled) {
      return;
    }
    const onScroll = throttle(() => {
      const containerBox = container.current?.getBoundingClientRect();
      const elBox = el.current?.getBoundingClientRect();

      if (!elBox || !containerBox) {
        return;
      }

      if (elBox.top - containerBox.bottom < distance) {
        cb();
      }
    }, 100);

    return subscribeEvent(container.current, "scroll", onScroll);
  }, [container.current, el.current, enabled, distance]);
};

interface IOption<V> {
  label: string;
  value: V;
  [key: string]: any;
}
interface IFilterFormProps<V> {
  title: string;
  useOptions: (
    query: string
  ) => Omit<UseInfiniteQueryResult<Array<IOption<V>>>, "data"> & { data: Array<IOption<V>> };
  values: Set<V>;
  onChange: (v: V[]) => void;
  renderOptionLabel?: (option: IOption<V>) => JSX.Element | string;
}
function FilterForm<V>({
  title,
  useOptions,
  values,
  onChange,
  renderOptionLabel,
}: IFilterFormProps<V>) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const loadMoreRef = useRef<HTMLButtonElement | null>(null);
  const [search, setSearch] = useState("");
  const { isLoading, data, fetchNextPage, hasNextPage, isFetchingNextPage } = useOptions(search);
  const options = data || [];
  const isAllChecked = new Set(options.map((o) => o.value)).size === values?.size; // todo

  const isIndeterminate = !isAllChecked && values?.size > 0;
  const isChecked = (option: any) => values && values.has(option.value); // todo

  const toggleAll = () => {
    if (isAllChecked) {
      onChange([]);
    } else {
      onChange(options.map((o) => o.value));
    }
  };
  useFetchNext(
    containerRef,
    loadMoreRef,
    100,
    fetchNextPage,
    Boolean(hasNextPage) && !isFetchingNextPage
  );

  return (
    <Box
      ref={containerRef}
      as={Stack}
      sx={{ paddingX: space[3], paddingY: space[4] }}
      space={4}
      style={{ maxHeight: 500, overflow: "auto" }}
    >
      <Typography variant="subtitle2">{title}</Typography>
      <SearchInput
        showCancel
        onCancel={() => setSearch("")}
        onRequestSearch={setSearch}
        style={{ flexShrink: 0 }}
      ></SearchInput>
      <Stack>
        {!isLoading && options?.length === 0 ? null : (
          <CheckBox
            label="Select All"
            color="primary"
            checked={isAllChecked || isIndeterminate}
            indeterminate={isIndeterminate}
            onChange={toggleAll}
            style={{ flexShrink: 0 }}
          />
        )}

        <Loader loading={isLoading}>
          {options?.map((option, i) => {
            const checked = isChecked(option);
            const label = renderOptionLabel ? renderOptionLabel(option) : option.label;

            return (
              <CheckBox
                style={{ flexShrink: 0 }}
                key={option.label + option.value + i}
                label={label as any}
                checked={checked}
                onChange={() => {
                  const newSet = new Set(values);
                  if (checked) {
                    newSet.delete(option.value);
                  } else {
                    newSet.add(option.value);
                  }
                  onChange(Array.from(newSet));
                }}
              />
            );
          })}
          {options?.length === 0 && <Typography>No items found</Typography>}
        </Loader>
        {hasNextPage && (
          <Button
            size="small"
            color="secondary"
            ref={loadMoreRef}
            disabled={Boolean(isFetchingNextPage)}
            onClick={() => fetchNextPage()}
          >
            {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load more"
              : "Nothing more to load"}
          </Button>
        )}
      </Stack>
    </Box>
  );
}

export function useTableFilterPopover<V>(props: IFilterFormProps<V>) {
  return usePopover((api) => (
    <Popover
      {...api}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <FilterForm {...props} />
    </Popover>
  ));
}

export function ThWithFilter(
  props: HeaderCellProps & { filterOptions: any } // todo types
) {
  const hasFilters = Boolean(props.filterOptions);
  const filters = hasFilters ? props.filterOptions.useFilters() : {};
  const [filterNode, actions] = hasFilters
    ? useTableFilterPopover({
        onChange: props.filterOptions.getOnChange(filters),
        title: `Filter by ${props.column.Header}`,
        useOptions: props.filterOptions.useOptions,
        values: props.filterOptions.getValues(filters),
        renderOptionLabel: props.filterOptions.renderOptionLabel,
      })
    : [null];

  return (
    <DefaultThContent {...props}>
      <HStack space={3}>
        <span>{props.children}</span>
        {hasFilters && (
          <FiltersIcon
            fontSize="small"
            color={props.filterOptions.getValues(filters)?.size > 0 ? "primary" : "action"}
            onClick={(e) => actions?.onOpen(e.currentTarget as any)}
          />
        )}
        {filterNode}
      </HStack>
    </DefaultThContent>
  );
}
