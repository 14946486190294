import { reduce } from "lodash";
import { build, createEffects } from "okdux";
import { IUser, IUserToggle, IUpdated, gsuiteSensorService } from "api/cloud-sensors";
import { gsuiteAuth } from "api/auth/GSuiteAuth";
import { IGSuiteTenant } from "api/auth/interfaces";
import { apiErrorNotification, notification } from "modules/notification";
import { asyncAct } from "libs/restatex";
import { messages } from "./messages";

export const gsuiteEffects = createEffects(
  {
    fetchUsers: build.async<{ domain: string; data: { entities: IUser[] } }, boolean>(),
    login: asyncAct<IGSuiteTenant>(),
    logout: build.async(),
    setEnabled: build.async(),
    toggleAutoMonitoring: asyncAct<Pick<IUser, "monitored"> & { domain: string }>(),
    getUser: asyncAct<IGSuiteTenant>(),
    toggle: asyncAct<IUserToggle, Partial<IUser> & { domain: string }>(),
    import: asyncAct<{ domain: string; users: IUser[] }, IUserToggle[]>(),
    enableAll: asyncAct<any, Pick<IUser, "monitored"> & { domain: string }>(),
    enableForIds: asyncAct<string[], string[]>(),
    deleteDomain: asyncAct<string, string>(),
  },
  "gsuite"
);

export async function fetchUsers(domain: string) {
  const { success, request, failure } = gsuiteEffects.fetchUsers;

  request();
  try {
    const data = await gsuiteSensorService.fetchUsers(domain);
    success({ domain, data });
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function monitor(domain: string): Promise<boolean> {
  const { data } = await gsuiteSensorService.monitorUsers(domain);

  const totalChanges = reduce<IUpdated, number>(data, countChanges, 0);
  return Boolean(totalChanges);

  function countChanges(acc: number, changes: IUpdated[keyof IUpdated]): number {
    return acc + changes.length;
  }
}

export async function logout(domain: string) {
  const { success, request, failure } = gsuiteEffects.logout;

  request();
  try {
    await gsuiteAuth.logout(domain);
    success();
    notification.open({ message: messages.notifications.userDisconnected });
    return;
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.logoutError, description: error.message });
    return Promise.reject(error);
  }
}

export async function importUsers(users: IUser[], domain: string) {
  const { success, request, failure } = gsuiteEffects.import;

  request();
  try {
    await gsuiteSensorService.batch(users, domain);
    await getUser(true);
    await fetchUsers(domain);
    success({ users, domain });
    notification.open({ message: messages.notifications.successfullyImported });
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.importError, description: error.message });
  }
}

export async function login() {
  const { success, request, failure } = gsuiteEffects.login;
  request();
  try {
    const tenant = await gsuiteAuth.login();
    success(tenant);
  } catch (error: any) {
    failure(error);
    notification.error({
      message: messages.notifications.gsuiteLoginError,
      description: error.message,
    });
  }
}

export async function getUser(forceUpdate = false) {
  const { success, request, failure } = gsuiteEffects.getUser;
  request();
  try {
    const tenant = await gsuiteAuth.getUser(forceUpdate);
    success(tenant);
  } catch (error) {
    failure(error);
  }
}

export async function toggle({ id, monitored, domain }: any) {
  const { success, request, failure } = gsuiteEffects.toggle;
  request();
  try {
    await gsuiteSensorService.updateUser(id, domain, { monitored });
    notification.open({ message: messages.notifications.userMonitoring(monitored) });
    success({ id, monitored, domain });
  } catch (error: any) {
    failure({ id });
    notification.error(apiErrorNotification(error));
  }
}

export async function setMonitoring(
  params: Pick<IUser, "monitored"> & { domain: string; users: IUser[] }
) {
  const { success, request, failure } = gsuiteEffects.enableAll;
  request();
  try {
    await gsuiteSensorService.batch(
      params.users.map((u) => {
        return {
          ...u,
          monitored: params.monitored,
        };
      }),
      params.domain
    );
    notification.open({
      message: messages.notifications.allUserMonitoring(params.monitored),
    });
    success(params);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function setEnabled(params: { enabled: boolean; domain: string }) {
  const { success, request, failure } = gsuiteEffects.setEnabled;
  request();
  try {
    await gsuiteSensorService.setEnabled(params);
    await getUser(true);
    await fetchUsers(params.domain);
    success(params);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAutoMonitoring(params: Pick<IUser, "monitored"> & { domain: string }) {
  const { success, request, failure } = gsuiteEffects.toggleAutoMonitoring;
  request();
  try {
    await gsuiteSensorService.toggleAutoMonitoring(params);
    await Promise.all([fetchUsers(params.domain), getUser(true)]);
    notification.open({
      message: messages.notifications.autoMonitoring(params.monitored),
    });
    success(params);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function deleteDomain(domain: string) {
  const { success, request, failure } = gsuiteEffects.deleteDomain;
  request();
  try {
    await gsuiteSensorService.deleteDomain(domain);
    await getUser(true);

    notification.open({
      message: messages.notifications.domainDeleted(domain),
    });
    success(domain);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}
export async function createDomain(email: string) {
  try {
    const res = await gsuiteSensorService.createDomain(email);
    notification.open({
      message: messages.notifications.domainCreated(res.data.domain_id),
    });
    await getUser(true);
  } catch (error: any) {
    notification.error(apiErrorNotification(error));
  }
}
