import React, { useState, useCallback } from "react";

export type ModalArgs<T extends any = {}> = {
  open: boolean;
  onClose: (event?: any, reason?: string) => void;
  extraProps?: T;
};

export type ModalAPI = {
  open: boolean;
  handleClose: () => void;
  handleOpen: (args?: any) => void;
};

export function useModal<T = {}>(
  render: (args: ModalArgs<T>) => React.ReactNode,
  disableBackdropClick?: boolean
) {
  const [state, setState] = React.useState<ModalArgs<T>>({
    open: false,
    extraProps: {} as T,
    onClose: () => {},
  });

  const onOpen = React.useCallback((extraProps: T = {} as T) => {
    setState({ open: true, extraProps, onClose });
  }, []);

  const onClose = React.useCallback(
    (_: any, reason?: string) => {
      if (!disableBackdropClick || reason !== "backdropClick") {
        setState((prev) => ({ ...prev, open: false }));
      }
    },
    [disableBackdropClick]
  );

  return [
    render({ ...state, onClose }),
    { open: state.open, handleClose: onClose, handleOpen: onOpen },
  ] as [React.ReactNode, ModalAPI];
}

export type PopoverActions = {
  onClose: () => void;
  open: boolean;
  onOpen: (el: EventTarget) => void;
};

export function usePopover(
  fn: (api: any) => JSX.Element
): readonly [JSX.Element, PopoverActions & { anchorEl: EventTarget | null }] {
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);

  function handleOpen($el: React.MouseEvent<HTMLSpanElement, MouseEvent>["target"]) {
    setAnchorEl($el);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const api = { onClose: handleClose, open: Boolean(anchorEl), anchorEl };
  return [fn(api), { ...api, onOpen: handleOpen }] as const;
}

export function usePopoverDisclosure() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const onOpen = useCallback(
    ($el: React.MouseEvent<Element, MouseEvent>["target"]) => setAnchorEl($el as Element),
    []
  );
  const onClose = useCallback(() => setAnchorEl(null), []);
  return { onClose, open: Boolean(anchorEl), anchorEl, onOpen };
}

export function useDisclosure() {
  const [isOpen, setAnchorEl] = useState<boolean | undefined | null>(null);

  const onOpen = useCallback(($el?: boolean) => setAnchorEl($el), []);
  const onClose = useCallback(() => setAnchorEl(null), []);
  return { onClose, open: Boolean(isOpen), onOpen };
}
