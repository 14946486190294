import { build, createEffects } from "okdux";
import { resolveService } from "service";
import cookie from "js-cookie";
import { silentlyUpdateVersion } from "app/setupVersionCheck";
import { api } from "api";
import { getTokenExpirationTime, KEY, removeAuthTokens } from "api/auth/storage";
import { IUser } from "api/auth/interfaces";
import { logEvent, eventsConstants } from "modules/analytics/Analytics";

export const authEffects = createEffects(
  {
    login: build.async<IUser>(),
    signup: build.async<IUser>(),
    refreshToken: build.async<IUser>(),
    logout: build.async(),
  },
  "auth"
);

export async function refreshToken() {
  const svc = await resolveService("authService");
  await svc.refreshToken();
}

export const setTokenUpdateTimer = () => {
  let timer: any;
  let canceled = false;
  async function run() {
    if (canceled) {
      return;
    }
    const expirationTime = await getTokenExpirationTime();
    // token time left minus 15s so we have enough time amount to get new token from backend
    const refreshIn = Math.max((expirationTime || 0) - Date.now() - 15000, 0); // - 15s
    timer = setTimeout(() => {
      refreshToken().then(run);
    }, refreshIn);
  }
  return {
    run() {
      canceled = false;
      return run();
    },
    pause() {
      canceled = true;
      clearTimeout(timer);
    },
  };
};

export function handleTokenRefresh() {
  const timer = setTokenUpdateTimer();
  return {
    async run(user: IUser) {
      if (user?.id) {
        // if user exists we start update token timer
        timer.run();
      } else {
        // reset refresh interval on log out
        timer?.pause();
      }
    },
  };
}

export async function fetchMe(token: any) {
  const res = await api.auth.AuthService.UserGetCurrent({});
  const { success } = authEffects.login;
  success({ ...res, token } as any);
  return res;
}

export async function logout() {
  const { failure, success, request } = authEffects.logout;
  const svc = await resolveService("authService");
  request();
  try {
    removeAuthTokens();
    await svc.logout();
    logEvent(eventsConstants.auth.logout);
    // After logout there is a probability that feature flags for anonymous user are different.
    // To not trigger extra popup appearance we update version silently.
    silentlyUpdateVersion();
    success();
  } catch (error) {
    failure(error);
    throw error;
  }
}
// just fast method to get if user is authenticated, indexed db is slow
export function isAuthenticated() {
  return Boolean(cookie.get(KEY));
}
