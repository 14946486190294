import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "ds-ui/icons";
import { Text } from "ds-ui/molecules/Text";
import { getIconSize, getSeparatorSize, getTextVariant } from "./ds-utils";
import { type BreadcrumbsProps } from "./types";

/*
 * Breadcrumbs component
 * a11y guidance: https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb/examples/breadcrumb/
 * TODO: Implement a showHome prop that shows the home button, redirecting to the "home" of the app
 * TODO: Implement collapsible behaviour
 *  TODO: Allow adding classnames
 * */
export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const size = "m";
  return (
    <nav aria-label="Breadcrumb" className="flex-1 min-w-0">
      <ol className="w-full p-0 flex justify-items-start gap-2xl list-none text-text-secondary">
        {items.map((item, idx) => {
          const isLast = idx === items.length - 1;
          const icon =
            item.Icon &&
            React.cloneElement(item.Icon, {
              size: getIconSize(size),
            });

          if (isLast) {
            return (
              <li key={item.label} className="flex items-center gap-s overflow-x-hidden">
                {/*// TODO: Investigate if it's worth having a link on the last element from an A11y pov*/}
                {/*<RouterLink aria-current="page" to="" className="no-underline text-text-primary">*/}
                <Text
                  color={"primary"}
                  variant={getTextVariant(size)}
                  title={item.label}
                  className="flex items-center gap-s w-full"
                  noWrap
                >
                  {icon}{" "}
                  <Text variant={getTextVariant(size)} noWrap>
                    {item.label}
                  </Text>
                </Text>
                {/*</RouterLink>*/}
              </li>
            );
          }

          return (
            <li key={item.label} className="flex items-center gap-s flex-shrink-0">
              <RouterLink
                to={item.link}
                className="text-text-secondary no-underline decoration-text-secondary hover:text-text-primary hover:underline-text-primary transition-colors flex items-center gap-s"
              >
                <Text
                  variant={getTextVariant(size)}
                  className="flex items-top gap-s pointer-events-none"
                >
                  {icon}
                  {item.label}
                </Text>
              </RouterLink>
              <Icon name="ChevronRight" size={getSeparatorSize(size)} />
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
