import { ENV } from "runenv";
import { types, SensorNames } from "api/gen";
import { localTheme } from "./theme";
import { SensorType } from "./constants";
// this file should be moved to dataflow-core
export const riskyColors = {
  low_risky: localTheme.yellow,
  risky: localTheme.red,
  risky_or_low: localTheme.red,
  needs_review: localTheme.gray2,
  approved: localTheme.green,
} as Record<types.CategoryStatus, string>;

export function getColorForRisk(r: types.CategoryStatus) {
  return riskyColors[r] ?? riskyColors.needs_review;
}

export const getSensorNames = (sensorTypes: SensorType[]): string[] => {
  return Object.values(SensorNames).filter((name) =>
    sensorTypes.some((sensorType) => name.startsWith(sensorType))
  );
};

export const isEmailNotificationSupported = (rule: types.Category["rule"]) =>
  Boolean(rule?.create_incident) ||
  (ENV.FEATURES.AI_INCIDENT_DETECTION && !rule?.disable_ai_incidents);
