import React, { useState, ReactNode, forwardRef, ForwardedRef, useRef } from "react";
import { Icon, IconProps } from "ds-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { Button } from "ds-ui/molecules/Button";
import { Input as MuiInputBase, InputOwnProps } from "@mui/base/Input";
import {
  inputContainerStyles,
  labelStyles,
  wrapperStyles,
  prefixStyles,
  suffixStyles,
  inputStyles,
  iconStyles,
} from "./styles";

const InputRightIcon = ({
  rightIcon,
  disabled,
}: {
  rightIcon?: ExtendedIcon;
  disabled?: boolean;
}) => {
  const iconWrapperClass = iconStyles({ position: "right", disabled });

  if (disabled) {
    return (
      <div className={iconWrapperClass}>
        <Icon name="LockOutlined" size="m" />
      </div>
    );
  }

  if (!rightIcon) {
    return null;
  }

  const iconElement = <Icon {...rightIcon} size="m" />;
  return (
    <div className={iconWrapperClass}>
      {rightIcon.onClick ? (
        <Button weight="tertiary" testId="" onClick={rightIcon.onClick}>
          {iconElement}
        </Button>
      ) : (
        iconElement
      )}
    </div>
  );
};

const ExtraText = ({
  disabled,
  error,
  errorText,
  helperText,
  disabledText,
}: Pick<InputBaseProps, "disabledText" | "helperText" | "errorText" | "error" | "disabled">) => {
  if (disabled && disabledText) {
    return <span className="text-text-subtle">{disabledText}</span>;
  }

  if (error && errorText) {
    return <span className="text-semantic-error-primary">{errorText}</span>;
  }

  if (helperText) {
    return (
      <span className="flex gap-xs items-center text-text-subtle">
        <Icon name={"HelpOutlineOutlinedIcon"} size={"s"} />
        {helperText}
      </span>
    );
  }

  return null;
};

const MoreInfo = ({ content, href, target = "_self" }: MoreInfoProps) => {
  if (href) {
    return (
      <a className={"flex gap-xs items-center text-text-links text-s"} href={href} target={target}>
        {content}
        <Icon name="OpenInNewOutlined" size="s" />
      </a>
    );
  }

  return (
    <span className={"flex gap-xs items-center text-text-subtle text-s"}>
      <Icon name={"InfoOutlined"} size="s" />
      {content}
    </span>
  );
};

export type ExtendedIcon = Pick<IconProps, "name"> & {
  onClick?: () => void;
};

export type MoreInfoProps = {
  content: string;
  href?: string;
  target?: "_blank" | "_self";
};

export type InputBaseProps = Omit<
  InputOwnProps,
  "onChange" | "endAdornment" | "startAdornment" | "className" | "classes"
> & {
  /**
   * If present, a `label` element will be displayed together with the input.
   * The label element will be tied to the input through the htmlFor attribute
   */
  label?: string;
  moreInfo?: MoreInfoProps;
  /**
   * left side Node separated by a border from the main input
   * pass unStyled as true if you want the component to NOT apply padding
   * TODO: think for a better name
   * */
  prefix?: {
    content: ReactNode;
    unStyled?: boolean;
  };
  /**
   * right side Node separated by a border from the main input
   * pass unStyled as true if you want the component to NOT apply padding
   * TODO: think for a better name
   * */
  suffix?: {
    content: ReactNode;
    unStyled?: boolean;
  };
  // Icons
  leftIcon?: ExtendedIcon;
  rightIcon?: ExtendedIcon;
  // Text to be displayed under the input
  helperText?: string;
  error?: boolean;
  errorText?: string;
  successText?: string; // TODO: to implement
  /*
   * Text that will be displayed when the input is disabled
   * */
  disabledText?: string;
  // Word counter, if enabled, it will show
  showWordCount?: boolean;
  maxLength?: number;
  textAlign?: "left" | "center" | "right";
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "m" | "l";
  /*
   * If TRUE, it will force the field to take all horizontal space
   * */
  fullWidth?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
};

export const InputBase = forwardRef(function TextField(
  {
    label,
    moreInfo,
    prefix,
    suffix,
    leftIcon,
    rightIcon,
    helperText,
    showWordCount = false,
    maxLength,
    textAlign = "left",
    size = "m",
    onChange,
    fullWidth,
    containerRef,
    error,
    errorText,
    disabled,
    disabledText,
    ...props
  }: InputBaseProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [wordCount, setWordCount] = useState(0);
  const uid = useRef(uuidv4());
  const inputId = props.id || uid.current;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setWordCount(value.length);
    onChange?.(e);
  };

  return (
    <div className={wrapperStyles({ fullWidth })}>
      {/* Label and More Info */}
      {(label || moreInfo) && (
        <div className="flex justify-between mb-1">
          {label && (
            <label className={labelStyles({ disabled })} htmlFor={inputId}>
              {label}
            </label>
          )}
          {moreInfo && <MoreInfo {...moreInfo} />}
        </div>
      )}

      {/* Input Container */}
      <div className={inputContainerStyles({ error, disabled })} ref={containerRef}>
        {/* Prefix */}
        {prefix && (
          <div className={prefixStyles({ styled: !prefix.unStyled })}>{prefix.content}</div>
        )}

        {/* Left Icon */}
        {leftIcon && (
          <div className={iconStyles({ position: "left", disabled: disabled })}>
            {leftIcon.onClick ? (
              <Button weight="tertiary" testId="" onClick={leftIcon.onClick}>
                <Icon {...leftIcon} size="m" />
              </Button>
            ) : (
              <Icon {...leftIcon} size="m" />
            )}
          </div>
        )}

        {/* Weird TS error when passing props like this along with using Omit<InputProps> so we need to cast props */}
        <MuiInputBase
          {...(props as InputOwnProps)}
          slotProps={{
            input: {
              className: inputStyles({
                size,
                textAlign,
                multiline: props.multiline,
                disabled,
              }),
              maxLength: maxLength,
              ref: ref,
            },
            root: {
              className: "flex-grow",
            },
          }}
          onChange={handleInputChange}
          id={inputId}
          error={error}
          disabled={disabled}
        />

        {/* Right Icon */}
        <InputRightIcon rightIcon={rightIcon} disabled={disabled} />

        {/* Suffix */}
        {suffix && (
          <div
            className={suffixStyles({
              styled: !suffix.unStyled,
            })}
          >
            {suffix.content}
          </div>
        )}
      </div>

      {/* Helper Text and Word Count */}
      <div className="flex justify-between mt-s text-xs gap-s">
        <ExtraText
          error={error}
          disabled={disabled}
          disabledText={disabledText}
          errorText={errorText}
          helperText={helperText}
        />
        {showWordCount && (
          <span className="ml-auto text-text-subtle flex-shrink-0">
            {wordCount}/{maxLength || "∞"}
          </span>
        )}
      </div>
    </div>
  );
});
