import { startCase } from "lodash";
import { DEFAULT_DATE_FORMAT } from "shared";
import {
  GridColType,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
} from "@mui/x-data-grid-pro";
import { CELConditionOperator, CELOperators, celCombine, lower, lowerCEL } from "libs/cel-query";
import { format } from "libs/time";
import { addLocalTZOffset, shiftDateByTimezoneOffset } from "libs/timezone/timezoneUtils";
import { CHGridColDef } from "./types";
import {
  GridFieldFilter,
  GridFieldFilterItem,
  GridFilterOperator,
  GridFilters,
} from "./filterTypes";

export function mapMuiFilterModelToFilters(
  filterModel: GridFilterModel,
  columns: CHGridColDef[]
): GridFilters {
  return filterModel.items.reduce((filters: GridFilters, item) => {
    const field = item.field;
    if (!item.value && item.operator !== "isEmpty" && item.operator !== "isNotEmpty") {
      // Mui sends filter model updates with undefined values when user has not started typing yet
      return filters;
    }
    if (!filters[field]) {
      filters[field] = {
        field,
        type: columns.find((column) => column.field === field)?.type || "string",
        items: [],
      };
    }

    if (filters[field].items.length) {
      filters[field].items.push({
        itemType: "condition",
        operator: filterModel.logicOperator || GridLogicOperator.Or,
      });
    }

    filters[field].items.push({
      itemType: "filter",
      value: item.value,
      operator: item.operator as GridFilterOperator,
      id: item.id,
    });

    return filters;
  }, {});
}

export function mapFiltersToMuiFilterModel(filters: GridFilters): GridFilterModel {
  const filterWithLink = Object.values(filters).find((filter) =>
    filter.items.some((item) => item.itemType === "condition")
  );
  const linkOperator =
    filterWithLink?.items?.find((item) => item.itemType === "condition")?.operator || "or";

  return {
    items: Object.values(filters).reduce(
      (items: GridFilterItem[], filter) => [
        ...items,
        ...filter.items
          .filter((item) => item.itemType !== "condition")
          .map((item) => ({
            operator: item.operator,
            field: filter.field,
            value: item.value,
            id: item.id,
          })),
      ],
      []
    ),
    logicOperator: linkOperator as GridLogicOperator,
  };
}

export function getGridFilterItemLabel(
  type: GridColType,
  item: GridFieldFilterItem,
  options: { timezone?: string }
): string {
  switch (type) {
    case "number":
    case "list":
    case "string": {
      const operator = startCase(item.operator).toLowerCase() || item.operator;
      if (!item.value) {
        return operator;
      }

      const values = Array.isArray(item.value) ? item.value : [item.value];
      const formattedValues =
        type === "string" || type === "list" ? values.map((v) => `"${v}"`) : values;

      return `${operator} ${formattedValues.join(", ")}`;
    }
    case "date":
    case "dateTime": {
      const operator = startCase(item.operator).toLowerCase() || item.operator;
      if (!item.value) {
        return operator;
      }

      const value = item.value as string;
      const date = options.timezone
        ? shiftDateByTimezoneOffset(addLocalTZOffset(value), options.timezone)
        : addLocalTZOffset(value);

      return `${operator} ${format(date, DEFAULT_DATE_FORMAT)}`;
    }
    case "boolean":
      return item.value ? "yes" : "no";
  }

  throw new UnknownFilterTypeError(type);
}

export type GridCustomTransformers = Record<
  string,
  (item: GridFieldFilterItem, defaultTransformer: FilterTransformer) => string | void | undefined
>;
export function transformGridFiltersToCEL(
  filters: GridFilters,
  transformers?: GridCustomTransformers
): string {
  return Object.values(filters)
    .map((filter) => transformFilterToCEL(filter, transformers))
    .filter((item) => Boolean(item))
    .map((item) => (item.startsWith("!") ? item : `(${item})`))
    .join(` ${CELConditionOperator.AND} `);
}

function transformFilterToCEL(
  filter: GridFieldFilter,
  transformers?: GridCustomTransformers
): string {
  const customTransformer = transformers?.[filter.field];
  const defaultTransformer = filterItemsTransformers[filter.type];
  if (!customTransformer && !defaultTransformer) {
    throw new UnknownFilterTypeError(filter.type);
  }

  return filter.items
    .filter(
      (item) =>
        item.itemType === "condition" ||
        item.operator === "isEmpty" ||
        item.operator === "isNotEmpty" ||
        item.operator === "exists" ||
        item.value
    )
    .map((item) => {
      if (item.itemType === "condition") {
        return transformConditionOperatorToCel(item.operator as GridLogicOperator);
      }

      const itemExpression =
        customTransformer?.(item, defaultTransformer) || defaultTransformer(filter.field, item);

      if (item.negated && filter.type === "string") {
        return `${filter.field} != "" ${CELConditionOperator.AND} !(${itemExpression})`;
      }

      return item.negated ? `!(${itemExpression})` : itemExpression;
    })
    .join(" && ");
}

type FilterTransformer = (
  field: string,
  item: GridFieldFilterItem,
  valueAccessor?: (field: string) => string
) => string;
const filterItemsTransformers: Record<GridColType, FilterTransformer> = {
  boolean: (field, item) => {
    try {
      const bValue = JSON.parse((item.value as string) ?? "false");
      switch (bValue) {
        case true:
          return field;
        case false:
          return `!${field}`;
      }
    } catch (e) {
      console.error(e);
      // we'll throw below anyways
    }

    throw new FilterItemTransformError("boolean", item.value as string);
  },
  singleSelect: () => {
    return "";
  },
  actions: () => {
    return "";
  },
  string: (field, item) => {
    switch (item.operator) {
      case "is":
      case "equals":
        return CELOperators.string.anyOf(
          field,
          getFilterMultipleValues(item).map(CELOperators.toString),
          item.case_sensitive
        );
      case "contains":
        return CELOperators.string.contains(
          field,
          getFilterMultipleValues(item),
          item.case_sensitive
        );
      case "startsWith":
        return CELOperators.string.startsWith(field, item.value, item.case_sensitive);
      case "endsWith":
        return CELOperators.string.endsWith(field, item.value, item.case_sensitive);
      case "matchesWith":
        return CELOperators.string.matchesWith(field, item.value, item.case_sensitive);
      case "isEmpty":
        return CELOperators.common.empty(field);
      case "isNotEmpty":
        return CELOperators.common.notEmpty(field);
      case "exists":
        return CELOperators.common.exists(field);
    }

    throw new FilterItemTransformError("string", item.operator);
  },
  list: (field, item, valueAccessor) => {
    switch (item.operator) {
      case "is":
        return CELOperators.list.existsIs(field, getFilterMultipleValues(item).map(lower), (item) =>
          lowerCEL(valueAccessor ? valueAccessor(item) : item)
        );
      case "contains": {
        return CELOperators.list.existsContains(
          field,
          getFilterMultipleValues(item),
          valueAccessor
        );
      }
      case "doesNotContain": {
        return `!(${CELOperators.list.existsContains(
          field,
          getFilterMultipleValues(item),
          valueAccessor
        )})`;
      }
      case "startsWith":
        return CELOperators.list.startsWith(field, getFilterMultipleValues(item), valueAccessor);
      case "endsWith":
        return CELOperators.list.endsWith(field, getFilterMultipleValues(item), valueAccessor);
      case "matchesWith":
        return CELOperators.list.matchesWith(field, getFilterMultipleValues(item), valueAccessor);
      case "isEmpty":
        return CELOperators.list.empty(field, valueAccessor);
      case "isNotEmpty":
      case "exists":
        return CELOperators.list.notEmpty(field, valueAccessor);
    }

    throw new FilterItemTransformError("string", item.operator);
  },
  number: (field, item) => {
    const value = Array.isArray(item.value) ? item.value[0] : item.value;
    switch (item.operator) {
      case "is":
        return CELOperators.common.in(field, getFilterMultipleValues(item));
      case "=":
        return CELOperators.common.equal(field, value);
      case "!=":
        return CELOperators.common.notEqual(field, value);
      case ">":
        return CELOperators.number.greater(field, value);
      case "<":
        return CELOperators.number.less(field, value);
      case "<=":
        return CELOperators.number.lessOrEqual(field, value);
      case ">=":
        return CELOperators.number.greaterOrEqual(field, value);
      case "isBetween": {
        const values = getFilterMultipleValues(item);
        if (values.length !== 2) {
          throw new Error("isBetween operator requires 2 values");
        }
        return celCombine(
          CELConditionOperator.AND,
          CELOperators.number.greaterOrEqual(field, values[0]),
          CELOperators.number.lessOrEqual(field, values[1])
        );
      }
      case "isEmpty":
        return CELOperators.common.empty(field);
      case "isNotEmpty":
        return CELOperators.common.notEmpty(field);
    }

    throw new FilterItemTransformError("number", item.operator);
  },
  date: (field, item) => {
    const timestamp = CELOperators.toDate(
      new Date((getFilterMultipleValues(item)[0] || Date.now()) as string).toISOString()
    );
    switch (item.operator) {
      case "is":
        return CELOperators.common.equal(field, timestamp);
      case "isNot":
        return CELOperators.common.notEqual(field, timestamp);
      case "after":
        return CELOperators.number.greater(field, timestamp);
      case "onOrAfter":
        return CELOperators.number.greaterOrEqual(field, timestamp);
      case "before":
        return CELOperators.number.less(field, timestamp);
      case "onOrBefore":
        return CELOperators.number.lessOrEqual(field, timestamp);
      case "isEmpty":
        return CELOperators.common.empty(field);
      case "isNotEmpty":
        return CELOperators.common.notEmpty(field);
      case "exists":
        return CELOperators.common.exists(field, "timestamp(0)");
    }

    throw new FilterItemTransformError("date", item.operator);
  },
  dateTime: (field, item) => filterItemsTransformers.date(field, item),
};

function getFilterMultipleValues(item: GridFieldFilterItem): string[] {
  return Array.isArray(item.value) ? item.value! : [item.value!];
}

function transformConditionOperatorToCel(operator: GridLogicOperator): string {
  if (operator === GridLogicOperator.Or) {
    return CELConditionOperator.OR;
  }

  if (operator === GridLogicOperator.And) {
    return CELConditionOperator.AND;
  }

  throw new Error(`Failed to convert link operator ${operator} to CEL`);
}

class UnknownFilterTypeError extends Error {
  constructor(filterType: string) {
    super(`Unknown filter type ${filterType}`);
    this.name = "UnknownFilterTypeError";
  }
}

class FilterItemTransformError extends Error {
  constructor(type: string, operator: string) {
    super(`Failed to convert ${type} filter to CEL, unknown operator ${operator}`);
    this.name = "FilterItemTransformError";
  }
}
