import React, { useEffect, useState, useRef, useMemo } from "react";
import { findAll } from "highlight-words-core";
import escapeRegExp from "escape-string-regexp";
import { debounce } from "lodash";
import { MenuList, Popper, MenuItem } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { theme } from "ui/theme";
import { css } from "ui/css";
import { useSize } from "libs/hooks/useSize";
import { useAsyncCallback } from "libs/hooks/useAsync2";
import { usePrevious } from "libs/hooks";
import { useSuggest } from "./useSuggest";
import { ISuggester, Suggester } from "./suggesterTypes";

function useScrollFollower(index: number) {
  const listRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (listRef.current) {
      const skip = 5;
      const itemSize = 40;
      const nextIndex = Math.max(Math.ceil((index - skip) / skip) * skip, 0);
      listRef.current.scrollTop = nextIndex * itemSize;
    }
  }, [index]);
  return listRef;
}

function useFetchMethod(
  suggester: Suggester,
  textareaRef: React.MutableRefObject<HTMLTextAreaElement>
) {
  return useMemo(() => {
    const fn = debounce((res, req) => {
      suggester.resolveSuggestions(textareaRef.current).then(res).catch(req);
    }, 200);
    let prev: any;
    return () =>
      new Promise((res, req) => {
        prev && prev();
        fn(res, req);
        prev = res;
      });
  }, [textareaRef]);
}

export const useSuggesterData = ({ onBlur, suggester, textareaRef }: ISuggester) => {
  const fetchMethod = useFetchMethod(suggester, textareaRef);
  const [highlightWord, setHighlightWord] = useState<string | null>(null);
  const [fetchSuggestions, { error, loading, value: items }] = useAsyncCallback(fetchMethod, () => {
    setHighlightWord(suggester.resolveWord(textareaRef.current));
  });
  const prevValue = usePrevious(items);
  const suggestItems = !loading && !error && items ? items : prevValue || [];
  const { visible, focusedIndex, onItemClick } = useSuggest({
    items: suggestItems,
    textareaRef,
    onInputValueChange() {
      fetchSuggestions();
    },
    onBlur,
    replaceValue: (v: any) => {
      return suggester.insert(v);
    },
  });
  const sizes = useSize(textareaRef);
  const isOpen = Boolean(visible && Boolean(textareaRef.current)) && Boolean(suggestItems.length);

  return {
    sizes,
    visible,
    focusedIndex,
    onItemClick,
    suggestItems,
    highlightWord,
    loading,
    isOpen,
  };
};

export function SuggesterUI({
  textareaRef,
  onBlur,
  suggester,
  heading,
}: ISuggester & { heading?: string }) {
  const { focusedIndex, highlightWord, onItemClick, sizes, suggestItems, loading, isOpen } =
    useSuggesterData({ textareaRef, onBlur, suggester });

  return (
    <RenderSuggest
      heading={heading}
      loading={loading}
      anchorEl={textareaRef.current}
      open={isOpen}
      suggestions={suggestItems}
      highlightWord={highlightWord}
      onItemClick={onItemClick}
      focused={focusedIndex}
      width={sizes.width}
      key={useMemo(() => Math.random(), [sizes.height, suggestItems.length])}
    />
  );
}

type RenderSuggestProps = {
  open: boolean;
  loading: boolean;
  anchorEl: HTMLTextAreaElement;
  suggestions: any[];
  onItemClick: (event: any, item: any) => void;
  focused: number;
  width: number;
  highlightWord: string | null;
  heading?: string;
};

const contentStyles = css({
  maxHeight: "400px",
  overflow: "auto",
  background: "white",
  border: `1px solid ${theme.palette.divider}`,
  position: "relative",
});

function RenderSuggest({
  anchorEl,
  suggestions,
  onItemClick,
  focused,
  width,
  highlightWord,
  open,
  loading,
  heading,
}: RenderSuggestProps) {
  const listRef = useScrollFollower(focused);
  const focusedIndex = Math.min(focused, suggestions.length - 1);
  return (
    <Popper
      style={{ zIndex: 2000, maxWidth: 700, minWidth: width + 30, marginLeft: -10 }}
      placement="bottom-start"
      anchorEl={anchorEl}
      open={open}
      translate="no"
    >
      <div ref={listRef} className={contentStyles().className}>
        {loading && (
          <LinearProgress style={{ position: "absolute", top: 0, height: 4, width: "100%" }} />
        )}
        <MenuList
          dense
          style={{
            maxWidth: 700,
            pointerEvents: loading ? "none" : "all",
            opacity: loading ? 0.5 : 1,
          }}
        >
          {heading && (
            <MenuItem
              data-name={"suggestionItem"}
              dense
              style={{ height: "25px", padding: "0px 10px", color: "#ccc" }}
              key="top-10"
            >
              <span>{heading}</span>
            </MenuItem>
          )}
          {suggestions.map((el, i) => {
            const title = findAll({
              searchWords: [escapeRegExp(highlightWord || "")],
              textToHighlight: el.subtitle || "",
            }).map(({ end, highlight, start }: any, i: any) => {
              const text = el.subtitle.substr(start, end - start);
              if (highlight) {
                return (
                  <mark style={{ backgroundColor: "rgba(255, 255, 5, 0.6)" }} key={text + i}>
                    {text}
                  </mark>
                );
              }
              return text;
            });
            return (
              <MenuItem
                onClick={(e) => onItemClick(e, el)}
                title={el.tooltip || ""}
                data-name={"suggestionItem"}
                selected={i === focusedIndex}
                dense
                style={{ height: "25px", padding: "0px 10px" }}
                key={i}
              >
                <span>{title}</span>
              </MenuItem>
            );
          })}
        </MenuList>
      </div>
    </Popper>
  );
}
