import { ENV } from "runenv";
import { useCanHook } from "shared/permissions";

type CanPii = {
  canReadPii: boolean;
  shouldMaskPii: boolean;
};

export function useCanPii(): CanPii {
  if (!ENV.FEATURES.DATA_MASKING_ENABLED) {
    return { canReadPii: true, shouldMaskPii: false };
  }

  const canReadPii = useCanHook("pii__read");

  return {
    canReadPii: Boolean(canReadPii),
    shouldMaskPii: !canReadPii,
  };
}
