import React from "react";
import { Field, FormSpy } from "react-final-form";
import { spacing } from "shared/styles";
import {
  Card,
  DialogContent,
  DialogTitle,
  Grid,
  SvgIcon,
  Typography,
  FormHelperText,
  IconButton,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  Input,
  Switch,
  Button,
  MenuItem,
  ListItemText,
  CardHeader,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { darken } from "@mui/material/styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import { css, styled } from "ui/css";
import { LuceneField as FFLuceneField } from "modules/form-utils";
import { theme, mapErrorOnly } from "ui";

export const StyledDialogContent = styled(DialogContent, {
  display: "flex",
  flexDirection: "column",
  height: "700px",
  paddingBottom: spacing(1)({ theme }),
});

export const StyledDialogTitle = styled(DialogTitle, {
  display: "flex",
  alignItems: "center",
});

export const DialogTitleIconContainer = styled(SvgIcon, {
  marginRight: spacing(2)({ theme }),
});

export const FilterContainer = styled(Grid, {
  flex: "0 0 auto",
});

export const ListContainer = styled(Grid, {
  position: "relative",
  flex: "1 0 auto",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

export const ListContent = styled("div", {
  flex: 1,
  overflowY: "auto",
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: spacing(1)({ theme }),
  paddingBottom: spacing(1)({ theme }),
});

export const ListItemContainer = styled(Grid, {
  overflow: "hidden",
});

export const ListHeader = styled(Grid, {
  paddingTop: spacing(2)({ theme }),
  paddingBottom: spacing(1)({ theme }),
});

export const CustomIconButton = styled(IconButton, {
  "&:hover": {
    backgroundcolor: "transparent",
  },
});

export const CustomButton = styled(Button, {
  "&:hover": {
    backgroundcolor: "transparent",
  },
});

export const CardHeaderAction = css({
  margin: "0px",
  visibility: "hidden",
  alignSelf: "center",
});

export const StyledCartHeader = styled(CardHeader, {
  "background": theme.secondaryBackground,
  "borderRadius": theme.shape.borderRadius,
  "padding": "0 14px 0 9px",
  "height": "42px",
  "cursor": "pointer",
  "&:hover": {
    background: darken(theme.secondaryBackground, 0.05),
  },
});

export const CustomCard = styled(Card, {
  borderBottom: "1px solid white",
  padding: "0px 0px 0px 0px",
  borderRadius: "5px",
  marginBottom: "5px",
});

export const CustomCardContent = styled(CardContent, {
  paddingBottom: "0 !important",
  padding: "0 15px",
});

export const CardSpacingContent = styled("div", {
  "& > *": { paddingBottom: "8px" },
});

export function CustomSelect({
  label,
  onChange,
  onFocus,
  onBlur,
  value,
  options,
  InputProps = {},
  meta = {},
  ...rest
}: any) {
  const { error, helperText } = mapErrorOnly({ meta });
  return (
    <FormControl fullWidth {...rest} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        MenuProps={
          {
            "data-testid": rest["data-testid"] ? `${rest["data-testid"]}/menu` : undefined,
          } as any
        }
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        input={<Input name={label} {...InputProps} />}
      >
        {options.map((el: any) => (
          <MenuItem key={el.value} value={el.value} dense>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {el.icon}
              <ListItemText
                primary={el.label}
                style={{
                  marginLeft: theme.spacing(1),
                }}
              />
            </div>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export function SelectControl({
  label,
  onChange,
  errorLabel,
  onFocus,
  onBlur,
  value,
  InputProps = {},
  children,
  SelectProps,
  ...rest
}: any) {
  const handleChangeMultiple = (event: any) => {
    onChange(event.target.value);
  };
  return (
    <FormControl fullWidth {...rest}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value || []}
        onChange={handleChangeMultiple}
        onFocus={onFocus}
        onBlur={onBlur}
        input={<Input name={label} {...InputProps} />}
        {...SelectProps}
      >
        {children}
      </Select>
      {rest.error && <FormHelperText>{errorLabel}</FormHelperText>}
    </FormControl>
  );
}
export class SetInitialOnMount extends React.Component<{ name: string; value: any }> {
  form: any;
  componentDidMount() {
    const name = this.props.name;

    const value = this.props.value;
    setTimeout(() => {
      this.form && value && this.form.change(name, value);
    });
  }

  render() {
    return (
      <FormSpy subscription={{ initialValues: true }}>
        {({ form }) => {
          return (
            <Field name={this.props.name}>
              {(value) => {
                if (!value) {
                  this.form = form;
                }
                return null;
              }}
            </Field>
          );
        }}
      </FormSpy>
    );
  }
}
export const LuceneField = styled(FFLuceneField, {
  "&:not(:first-child)": { marginTop: spacing(2) },
  "minHeight": "fit-content",
});

export const StyledSwitch = styled(Switch, {
  height: "22px",
  marginRight: "-8px",
});

export const CustomCardActions = React.memo<{ name: string; showDots?: boolean }>(
  function CustomCardActions({ name, showDots = true }) {
    return (
      <Field name={name} type="checkbox">
        {({ input: { name, value, ...rest } }) => (
          <React.Fragment>
            {value && showDots && (
              <CustomIconButton style={{ width: 10 }}>
                <MoreVertIcon />
              </CustomIconButton>
            )}
            <StyledSwitch checked={value} {...rest} />
          </React.Fragment>
        )}
      </Field>
    );
  }
);

export function checkDotName(name: any) {
  return name ? name + "." : "";
}

export const LabelWithIcon = ({ label, icon }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {icon}
      <Typography
        style={{ display: "inline-flex", alignItems: "center", fontWeight: 500 }}
        variant="subtitle1"
      >
        {label}
      </Typography>
    </div>
  );
};

export const KeywordSearchLabel = ({ label, onDelete }: any) => {
  return (
    <>
      <Typography noWrap>{label}</Typography>
      {onDelete && (
        <IconButton onClick={onDelete} style={{ padding: "4px" }} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export const KeywordSearchNoFound = ({ children }: any) => (
  <Typography style={{ padding: "16px" }} align="left" variant="button" color="textSecondary">
    {children}
  </Typography>
);

export const spacingClassName = css({
  padding: `${spacing(3)({ theme })} ${spacing(4)({ theme })}`,
  margin: 0,
});

export function TogglerIcon({ isOpen }: any) {
  return (
    <KeyboardArrowDown
      color="action"
      style={{
        transition: "transform 0.3s ease",
        transform: !isOpen ? "rotate(-90deg)" : "",
        marginRight: "6px",
      }}
    />
  );
}

export * from "./LuceneHelperInfo";
export * from "./ScriptHelperInfo";
export * from "./Switch";
