export const messages = {
  notifications: {
    userDisconnected: "User has been disconnected!",
    logoutError: "Logout Error",
    domainCreated: (domain: string) => `Domain ${domain} has been created`,
    domainDeleted: (domain: string) => `Domain ${domain} successfully deleted`,
    successfullyImported: "Successfully Imported",
    importError: "Import Error",
    usersMonitoringEnabled: "Monitoring for provided users has been enabled",
    salesForceError: "SalesForce Login Error",
    o365LoginError: "O365 Login Error",
    onedriveLoginError: "O365 Login Error",
    gsuiteLoginError: "Gsuite Login Error",
    userMonitoring: (enabled: boolean) => `Monitoring has been ${enabled ? "enabled" : "disabled"}`,
    allUserMonitoring: (enabled: boolean) =>
      `Monitoring of all users ${enabled ? "enabled" : "disabled"}`,
    autoMonitoring: (enabled: boolean) =>
      `Automatic monitoring of all users ${enabled ? "enabled" : "disabled"}`,
    notConnectedMessage:
      "Please connect a user or select which user's connection you would like to configure.",
  },
  cloudSensors: "Cloud Sensors",
  listOfMonitoredUsers: "List of monitored users",
  enableAutoMonitoring: "Enable automatic monitoring for all users",
  warningMessage:
    "Some users do not have associated email address so they are excluded from the list",
  searchPlaceholder: "Search for user",
  selectAll: (count: number) => `Select all ${count}`,
  showOnlyMonitored: (count: number) => `Show only monitored users (${count})`,
  actions: "Actions",
  downloadUsersList: "Download users list",
  importUsersList: "Import users list",
  disconnect: "Disconnect",
  tenantId: "Tenant ID",
  connect: "+ Connect",
};
