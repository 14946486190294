import { useMemo } from "react";

/**
 *
 * @param fn computable function
 * @param args arguments which it requires
 */
export function useMemoize<T extends any[], R>(fn: (...args: T) => R, args: T): R {
  // eslint-disable-next-line prefer-spread
  return useMemo(() => fn.apply(null, args), args);
}
