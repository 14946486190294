import React from "react";
import MChip, { ChipProps } from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { css } from "ui/css";

const filterChipStyles = css({
  "height": 28,
  "background": "#fff",
  "borderColor": "#E0E0E0",
  "color": "rgba(0, 0, 0, .87)",
  "borderRadius": "4px",
  "fontWeight": 500,
  "fontSize": 13,
  "textTransform": "none",
  "justifyContent": "flex-start",
  "minWidth": 0,
  "&:active": {
    boxShadow: "none",
  },
  "&.MuiChip-clickable:hover": {
    background: "#fff",
    color: "rgba(0, 0, 0, .87)",
  },
  ".MuiChip-label": {
    color: "inherit",
    paddingRight: "0",
    paddingLeft: "8px",
    fontSize: 13,
    lineHeight: 1.2,
  },
  ".MuiChip-icon": {
    marginLeft: "6px",
    marginRight: "-2px",
    color: "rgba(0, 0, 0, .6)",
  },
  ".MuiChip-deleteIcon": {
    "display": "flex",
    "alignItems": "center",
    "padding": "0 4px 0 8px",
    "marginRight": 0,
    "marginLeft": "auto",
    "height": "100%",
    "color": "rgba(0, 0, 0, .6)",
    "&:hover": {
      color: "rgba(0, 0, 0, .6)",
    },
  },
});

export const FilterChip = React.forwardRef(function FilterChip(props: ChipProps, ref: any) {
  return (
    <MChip
      ref={ref}
      variant="outlined"
      deleteIcon={
        props.onDelete ? (
          <div>
            <CloseIcon fontSize="small" />
          </div>
        ) : undefined
      }
      {...props}
      className={`${filterChipStyles().className} ${props.className}`}
    />
  );
});
