import { reduce } from "lodash";
import { build, createEffects } from "okdux";
import { IUser, IUserToggle, IUpdated, onedriveSensorService } from "api/cloud-sensors";
import { onedriveAuth } from "api/auth/OnedriveAuth";
import { IOnedriveTenant } from "api/auth/interfaces";
import { apiErrorNotification, notification } from "modules/notification";
import { asyncAct } from "libs/restatex";
import { messages } from "./messages";

export const onedriveEffects = createEffects(
  {
    fetchUsers: build.async<IUser[], boolean>(),
    login: asyncAct<IOnedriveTenant>(),
    logout: build.async(),
    getUser: asyncAct<IOnedriveTenant>(),
    toggle: asyncAct<IUserToggle, Partial<IUser>>(),
    import: asyncAct<IUser[], IUserToggle[]>(),
    enableAll: asyncAct<any, Pick<IUser, "monitored">>(),
    enableForIds: asyncAct<string[], string[]>(),
  },
  "onedrive"
);

export async function fetchUsers() {
  const { success, request, failure } = onedriveEffects.fetchUsers;

  request();
  try {
    const data = await onedriveSensorService.fetchUsers();
    success(data);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function monitor(): Promise<boolean> {
  const { data } = await onedriveSensorService.monitorUsers();

  const totalChanges = reduce<IUpdated, number>(data, countChanges, 0);
  return Boolean(totalChanges);

  function countChanges(acc: number, changes: IUpdated[keyof IUpdated]): number {
    return acc + changes.length;
  }
}

export async function logout() {
  const { success, request, failure } = onedriveEffects.logout;

  request();
  try {
    await onedriveAuth.logout();
    success();
    notification.open({ message: messages.notifications.userDisconnected });
    return;
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.logoutError, description: error.message });
    return Promise.reject(error);
  }
}

export async function importUsers(users: IUser[]) {
  const { success, request, failure } = onedriveEffects.import;

  request();
  try {
    await onedriveSensorService.batch(users);
    success(users);
    notification.open({ message: messages.notifications.successfullyImported });
  } catch (error: any) {
    failure(error);
    notification.error({ message: messages.notifications.importError, description: error.message });
  }
}

export async function enableForIds(users: string[]) {
  const { success, request, failure } = onedriveEffects.enableForIds;

  request();
  try {
    await onedriveSensorService.batch(users.map((id) => ({ id, monitored: true })));
    success(users);
    notification.open({ message: messages.notifications.usersMonitoringEnabled });
  } catch (error: any) {
    failure(error);
    notification.error({ message: error.message });
  }
}

export async function login() {
  const { success, request, failure } = onedriveEffects.login;
  request();
  try {
    const tenant = await onedriveAuth.login();
    success(tenant);
  } catch (error: any) {
    failure(error);
    notification.error({
      message: messages.notifications.onedriveLoginError,
      description: error.message,
    });
  }
}

export async function getUser() {
  const { success, request, failure } = onedriveEffects.getUser;
  request();
  try {
    const tenant = await onedriveAuth.getUser();
    success(tenant);
  } catch (error) {
    failure(error);
  }
}

export async function toggle({ id, monitored }: any) {
  const { success, request, failure } = onedriveEffects.toggle;
  request();
  try {
    await onedriveSensorService.updateUser(id, { monitored });
    notification.open({ message: messages.notifications.userMonitoring(monitored) });
    success({ id, monitored });
  } catch (error: any) {
    failure({ id });
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAll(params: Pick<IUser, "monitored">) {
  const { success, request, failure } = onedriveEffects.enableAll;
  request();
  try {
    await onedriveSensorService.updateAll(params);
    notification.open({
      message: messages.notifications.allUserMonitoring(params.monitored),
    });
    success(params);
  } catch (error: any) {
    failure();
    notification.error(apiErrorNotification(error));
  }
}

export async function toggleAutoMonitoring(params: Pick<IUser, "monitored">) {
  try {
    await onedriveSensorService.toggleAutoMonitoring(params);
    await fetchUsers();
    notification.open({
      message: messages.notifications.autoMonitoring(params.monitored),
    });
  } catch (error: any) {
    notification.error(apiErrorNotification(error));
  }
}
