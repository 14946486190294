import { useEffect, useRef } from "react";

export function useTimeout() {
  const ref = useRef<ReturnType<typeof setTimeout> | null>(null);

  function timeout(fn: () => void, ms?: number | undefined) {
    clear();
    ref.current = setTimeout(fn, ms);
  }

  function clear() {
    ref.current && clearTimeout(ref.current);
  }

  useEffect(() => {
    return clear;
  }, []);

  return { setTimeout: timeout, clearTimeout: clear };
}
