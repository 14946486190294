import React, { createElement } from "react";
import {
  LongArrowPure,
  PopupIconPure,
  StartedTracking,
  ActionAttach,
  ActionZip,
  ActionUnzip,
  ActionCopy,
  ActionEdit,
  ActionMove,
  ActionView,
  ActionDelete,
  ActionUpload,
  ActionReceive,
  ActionDownload,
  ActionCopypaste,
  ActionCreateCloud,
  ActionSent,
  ActionSentToPrinter,
  AddNew,
  File,
  Calendar,
  LocationMail,
  LocationMailBody,
  LocationCloud,
  LocationCloudStorage,
  LocationShare,
  LocationWebsite,
  LocationEndpoint,
  LocationEndpointUnmanaged,
  LocationEndpointApps,
  LocationRemovableMedia,
  LocationPrinter,
  ExpandRow,
  GraphExpand,
  SensitiveLocations,
  ResponseAlert,
  Filters,
  Expand,
  ExpandFull,
  UploadFile,
  Mac,
  Windows,
  Settings,
  Installer,
  InstallerOpen,
  History,
  Cmd,
  Collapse,
  FolderSettings,
  SortAsc,
  SortDesc,
  Sort,
  Export,
  Content,
  Event,
  Policy,
  ArrowDown,
  Planet,
  Search,
  SearchGrey,
  IncidentAssign,
  IncidentEdit,
  IncidentIgnored,
  IncidentCommentDeleted,
  IncidentMessage,
  IncidentResolved,
  IncidentNew,
  IncidentPhoto,
  IncidentUser,
  America,
  OtherCountries,
  Africa,
  Europe,
  Asia,
  Australia,
  WarningTriangle,
  WarningRound,
  LocalTime,
  Chat,
  CalendarFilled,
  DataflowsDashboard,
  Datasets,
  Policies,
  ActionHide,
  Flow,
  UsersWidget,
  Link,
  LinkBlue,
  UserProfile,
  DlpScanner,
  UninstallProtection,
  EndpointInstallers,
  EndpointGroups,
  TableSettings,
  Revert,
  AnalyticsDashboard,
  Check,
  Close,
  ArrowCircleUp,
  ExportDownIcon,
  Info,
  CalendarSimple,
  Assignee,
  WarningAlt,
  Block,
  LinkSquare,
  HelpCircle,
  ArrowRight,
  BlockResponse,
  WarnResponse,
  MonitorResponse,
  Email,
  VerifyUser,
  Camera,
  RemoveCircleOutlined,
  RemoveCircleCrossed,
  Screenshot,
  CopyFilled,
  CopyGrey,
  MatchingList,
  ListsMatchingBig,
  Trash,
  PlusSmall,
  UploadList,
  ArrowCircleExchange,
  ImportSmall,
  ExportSmall,
  ManagedList,
  Duplicate,
  ManagedListSmall,
  User,
  Copy,
  Linux,
  Administration,
  PolicyIncident,
  LineaIncident,
  MatchPathDelimitersOff,
  MatchPathDelimitersOn,
} from "assets/icons/internal";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function PopupWindowIcon() {
  return <PopupIconPure />;
}
function convertToDashCase(input: string): string {
  const words = input.split(/(?=[A-Z])/);
  return words.map((word) => word.toLowerCase()).join("-");
}

function Icon(props: any, component: any, noWrapper = false) {
  const finalProps = {
    ...props,
    "data-icon-testid": convertToDashCase(component?.type?.render?.name || ""),
  };
  if (noWrapper) {
    return React.cloneElement(component, finalProps);
  }
  return createElement(SvgIcon, finalProps, component);
}

export const LongArrow = (props: SvgIconProps) =>
  Icon({ color: "action", ...props }, <LongArrowPure />);

export * from "./ContentAttributeIcon";
export { ReactComponent as CHIcon3 } from "assets/icons/ch_icon3.svg";
export { ReactComponent as CHIcon4 } from "assets/icons/ch_icon4.svg";

export type IconType = (props: SvgIconProps) => JSX.Element;
export const AdministrationIcon: IconType = (props) => Icon(props, <Administration />);
export const StartedTrackingIcon: IconType = (props) => Icon(props, <StartedTracking />);
export const PlanetIcon: IconType = (props) => Icon(props, <Planet />);
export const ArrowDownIcon: IconType = (props) => Icon(props, <ArrowDown />);
export const ArrowDownIconCentered: IconType = (props) => Icon(props, <ArrowDown />, true);
export const ActionAttachIcon: IconType = (props) => Icon(props, <ActionAttach />);
export const ActionZipIcon: IconType = (props) => Icon(props, <ActionZip />);
export const ActionUnzipIcon: IconType = (props) => Icon(props, <ActionUnzip />);
export const ActionCopyIcon: IconType = (props) => Icon(props, <ActionCopy />);
export const ActionReceiveIcon: IconType = (props) => Icon(props, <ActionReceive />);
export const ActionCopypasteIcon: IconType = (props) => Icon(props, <ActionCopypaste />);
export const ActionDownloadIcon: IconType = (props) => Icon(props, <ActionDownload />);
export const ActionCreateCloudIcon: IconType = (props) => Icon(props, <ActionCreateCloud />);
export const ActionEditIcon: IconType = (props) => Icon(props, <ActionEdit />);
export const ActionMoveIcon: IconType = (props) => Icon(props, <ActionMove />);
export const ActionViewIcon: IconType = (props) => Icon(props, <ActionView />);
export const ActionHideIcon: IconType = (props) => Icon(props, <ActionHide />);
export const ActionUploadIcon: IconType = (props) => Icon(props, <ActionUpload />);
export const ActionDeleteIcon: IconType = (props) => Icon(props, <ActionDelete />);
export const ActionSentIcon: IconType = (props) => Icon(props, <ActionSent />);
export const ActionSentToPrinterIcon: IconType = (props) => Icon(props, <ActionSentToPrinter />);
export const AddNewIcon: IconType = (props) => Icon(props, <AddNew />, true);
export const LocationMailIcon: IconType = (props) => Icon(props, <LocationMail />);
export const LocationMailBodyIcon: IconType = (props) => Icon(props, <LocationMailBody />);
export const LocationEndpointAppsIcon: IconType = (props) => Icon(props, <LocationEndpointApps />);
export const LocationCloudIcon: IconType = (props) => Icon(props, <LocationCloud />);
export const LocationShareIcon: IconType = (props) => Icon(props, <LocationShare />);
export const LocationEndpointIcon: IconType = (props) => Icon(props, <LocationEndpoint />);
export const LocationEndpointUnmanagedIcon: IconType = (props) =>
  Icon(props, <LocationEndpointUnmanaged />);
export const LocationWebsiteIcon: IconType = (props) => Icon(props, <LocationWebsite />);
export const LocationPrinterIcon: IconType = (props) => Icon(props, <LocationPrinter />);
export const ExpandIcon: IconType = (props) => Icon(props, <Expand />);
export const ExpandFullIcon: IconType = (props) => Icon(props, <ExpandFull />);
export const LocationCloudStorageIcon: IconType = (props) => Icon(props, <LocationCloudStorage />);
export const LocationRemovableMediaIcon: IconType = (props) =>
  Icon(props, <LocationRemovableMedia />);
export const FileIcon: IconType = (props) => Icon(props, <File />);
export const ExpandRowIcon: IconType = (props) => Icon(props, <ExpandRow />);
export const CalendarIcon: IconType = (props) => Icon(props, <Calendar />);
export const GraphExpandIcon: IconType = (props) => Icon(props, <GraphExpand />);
export const SensitiveLocationsIcon: IconType = (props) => Icon(props, <SensitiveLocations />);
export const SelectIcon: IconType = (props) => Icon(props, <path d="M7 10l5 5 5-5z" />);
export const ResponseIcon: IconType = (props) => Icon(props, <ResponseAlert />);
export const FiltersIcon: IconType = (props) => Icon(props, <Filters />);
export const UploadFileIcon: IconType = (props) => Icon(props, <UploadFile />);
export const MacIcon: IconType = (props) => Icon(props, <Mac />);
export const LinuxIcon: IconType = (props) => Icon(props, <Linux />);
export const WindowsIcon: IconType = (props) => Icon(props, <Windows />);
export const SettingsIcon: IconType = (props) => Icon(props, <Settings />);
export const InstallerIcon: IconType = (props) => Icon(props, <Installer />);
export const InstallerOpenIcon: IconType = (props) => Icon(props, <InstallerOpen />);
export const HistoryIcon: IconType = (props) => Icon(props, <History />);
export const CmdIcon: IconType = (props) => Icon(props, <Cmd />);
export const ScreenshotIcon: IconType = (props) => Icon(props, <Screenshot />, true);
export const CollapseIcon: IconType = (props) => Icon(props, <Collapse />);
export const FolderSettingsIcon: IconType = (props) => Icon(props, <FolderSettings />);
export const SortDescIcon: IconType = (props) => Icon(props, <SortDesc />);
export const SortAscIcon: IconType = (props) => Icon(props, <SortAsc />);
export const SortIcon: IconType = (props) => Icon(props, <Sort />);
export const ExportIcon: IconType = (props) => Icon(props, <Export />);
export const SearchIcon: IconType = (props) => Icon(props, <Search />);
export const SearchGreyIcon: IconType = (props) => Icon(props, <SearchGrey />);
export const ContentIcon: IconType = (props) => Icon(props, <Content />);
export const EventIcon: IconType = (props) => Icon(props, <Event />);
export const PolicyIcon: IconType = (props) => Icon(props, <Policy />);
export const IncidentAssignIcon: IconType = (props) => Icon(props, <IncidentAssign />);
export const IncidentEditIcon: IconType = (props) => Icon(props, <IncidentEdit />);
export const IncidentIgnoredIcon: IconType = (props) => Icon(props, <IncidentIgnored />);
export const IncidentMessageIcon: IconType = (props) => Icon(props, <IncidentMessage />);
export const IncidentCommentDeletedIcon: IconType = (props) =>
  Icon(props, <IncidentCommentDeleted />);
export const IncidentResolvedIcon: IconType = (props) => Icon(props, <IncidentResolved />);
export const IncidentNewIcon: IconType = (props) => Icon(props, <IncidentNew />);
export const IncidentPhotoIcon: IconType = (props) => Icon(props, <IncidentPhoto />);
export const IncidentUserIcon: IconType = (props) => Icon(props, <IncidentUser />);
export const AmericaIcon: IconType = (props) => Icon(props, <America />, true);
export const OtherCountriesIcon: IconType = (props) => Icon(props, <OtherCountries />, true);
export const AfricaIcon: IconType = (props) => Icon(props, <Africa />, true);
export const EuropeIcon: IconType = (props) => Icon(props, <Europe />, true);
export const AsiaIcon: IconType = (props) => Icon(props, <Asia />, true);
export const AustraliaIcon: IconType = (props) => Icon(props, <Australia />, true);
export const ChatIcon: IconType = (props) => Icon(props, <Chat />, true);
export const WarningTriangleIcon: IconType = (props) => Icon(props, <WarningTriangle />);
export const WarningRoundIcon: IconType = (props) => Icon(props, <WarningRound />, true);
export const LocalTimeIcon: IconType = (props) => Icon(props, <LocalTime />, true);
export const CalendarFilledIcon: IconType = (props) => Icon(props, <CalendarFilled />);
export const DatasetsIcon: IconType = (props) => Icon(props, <Datasets />);
export const PoliciesIcon: IconType = (props) => Icon(props, <Policies />);
export const FlowIcon: IconType = (props) => Icon(props, <Flow />);
export const DataflowsDashboardIcon: IconType = (props) => Icon(props, <DataflowsDashboard />);
export const UsersWidgetIcon: IconType = (props) => Icon(props, <UsersWidget />);
export const LinkIcon: IconType = (props) => Icon(props, <Link />);
export const LinkBlueIcon: IconType = (props) => Icon(props, <LinkBlue />, true);
export const UserProfileIcon: IconType = (props) => Icon(props, <UserProfile />);
export const DlpScannerIcon: IconType = (props) => Icon(props, <DlpScanner />);
export const UninstallProtectionIcon: IconType = (props) => Icon(props, <UninstallProtection />);
export const EndpointGroupsIcon: IconType = (props) => Icon(props, <EndpointGroups />);
export const EndpointInstallersIcon: IconType = (props) => Icon(props, <EndpointInstallers />);
export const TableSettingsIcon: IconType = (props) => Icon(props, <TableSettings />, true);
export const RevertIcon: IconType = (props) => Icon(props, <Revert />);
export const AnalyticsDashboardIcon: IconType = (props) => Icon(props, <AnalyticsDashboard />);
export const CheckIcon: IconType = (props) => Icon(props, <Check />, true);
export const CloseIcon: IconType = (props) => Icon(props, <Close />);
export const ArrowCircleUpIcon: IconType = (props) => Icon(props, <ArrowCircleUp />, true);
export const ExportDownIconIcon: IconType = (props) => Icon(props, <ExportDownIcon />);
export const InfoIcon: IconType = (props) => Icon(props, <Info />);
export const CalendarSimpleIcon: IconType = (props) => Icon(props, <CalendarSimple />);
export const AssigneeIcon: IconType = (props) => Icon(props, <Assignee />);
export const WarningAltIcon: IconType = (props) => Icon(props, <WarningAlt />);
export const BlockIcon: IconType = (props) => Icon(props, <Block />);
export const LinkSquareIcon: IconType = (props) => Icon(props, <LinkSquare />, true);
export const HelpCircleIcon: IconType = (props) => Icon(props, <HelpCircle />, true);
export const ArrowRightIcon: IconType = (props) => Icon(props, <ArrowRight />, true);
export const BlockResponseIcon: IconType = (props) => Icon(props, <BlockResponse />, true);
export const WarnResponseIcon: IconType = (props) => Icon(props, <WarnResponse />, true);
export const MonitorResponseIcon: IconType = (props) => Icon(props, <MonitorResponse />, true);
export const EmailIcon: IconType = (props) => Icon(props, <Email />, true);
export const VerifyUserIcon: IconType = (props) => Icon(props, <VerifyUser />, true);
export const CameraIcon: IconType = (props) => Icon(props, <Camera />, true);
export const RemoveCircleOutlinedIcon: IconType = (props) => Icon(props, <RemoveCircleOutlined />);
export const RemoveCircleCrossedIcon: IconType = (props) =>
  Icon(props, <RemoveCircleCrossed />, true);
export const CopyFilledIcon: IconType = (props) => Icon(props, <CopyFilled />, true);
export const CopyGreyIcon: IconType = (props) => Icon(props, <CopyGrey />, true);
export const CopyIcon: IconType = (props) => Icon(props, <Copy />, true);
export const MatchingListIcon: IconType = (props) => Icon(props, <MatchingList />, true);
export const ListsMatchingBigIcon: IconType = (props) => Icon(props, <ListsMatchingBig />);
export const TrashIcon: IconType = (props) => Icon(props, <Trash />);
export const PlusSmallIcon: IconType = (props) => Icon(props, <PlusSmall />, true);
export const UploadListIcon: IconType = (props) => Icon(props, <UploadList />, true);
export const ArrowCircleExchangeIcon: IconType = (props) =>
  Icon(props, <ArrowCircleExchange />, true);
export const ImportSmallIcon: IconType = (props) => Icon(props, <ImportSmall />, true);
export const ExportSmallIcon: IconType = (props) => Icon(props, <ExportSmall />, true);
export const ManagedListIcon: IconType = (props) => Icon(props, <ManagedList />, true);
export const DuplicateIcon: IconType = (props) => Icon(props, <Duplicate />, true);
export const ManagedListSmallIcon: IconType = (props) => Icon(props, <ManagedListSmall />, true);
export const UserIcon: IconType = (props) => Icon(props, <User />, true);
export const PolicyIncidentIcon: IconType = (props) => Icon(props, <PolicyIncident />, true);
export const LineaIncidentIcon: IconType = (props) => Icon(props, <LineaIncident />, true);
export const MatchPathDelimitersOffIcon: IconType = (props) =>
  Icon(props, <MatchPathDelimitersOff />, true);
export const MatchPathDelimitersOnIcon: IconType = (props) =>
  Icon(props, <MatchPathDelimitersOn />, true);
