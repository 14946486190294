import React from "react";
import clsx from "classnames";
import { Text } from "../Text";

const beforeClasses =
  "before:content-[''] before:rounded before:absolute before:w-4xl before:h-1 before:bg-text-primary before:bottom-0 before:left-0 before:z-10";

export type PageTitleProps = {
  title: string;
};
export const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <Text
      as="h1"
      variant="primaryTitle"
      color={"primary"}
      className={clsx("relative m-0", beforeClasses)}
    >
      {title}
    </Text>
  );
};
