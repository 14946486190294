import { useRef, useState, useEffect, RefObject, useMemo } from "react";
import { keyBy } from "lodash";
import { useSize } from "libs/hooks/useSize";
import { Column } from "./types";
import { useResizeListener } from "./useResizeListener";
import {
  calculateCurrentSizes,
  calculateRealSizes,
  isResizePossible,
  parseMinMaxSizes,
  SizeNode,
} from "./resizeServices";

export function useResize(columns: Column[], tableRef: RefObject<HTMLElement>) {
  const { pageXDiff, onMouseDown, curCol } = useResizeListener();
  const tableSize = useSize(tableRef);
  const [sizes, setSizes] = useState<Array<{ width: number }>>();
  const realSizesRef = useRef<Map<string, number>>();
  const prevRealSizesRef = useRef<Map<string, number>>();
  const minMaxSizesRef = useRef<Map<string, [number, number]>>();
  const prevTableWidthRef = useRef(0);

  // calculate new sizes after table resize
  useEffect(() => {
    if (sizes && prevRealSizesRef.current) {
      const prevTableWidth = prevTableWidthRef.current;
      const newSizes = columns.map((c) => {
        if (!c.resizable) {
          return c;
        }
        const prevWidth = prevRealSizesRef.current!.get(c.id);
        const scaleFactor = prevWidth! / prevTableWidth;

        return { id: c.id, width: scaleFactor * tableSize.width };
      });

      setSizes(newSizes as Array<{ width: number }>);
    }
  }, [columns, tableSize.width]);

  useEffect(() => {
    minMaxSizesRef.current = parseMinMaxSizes(columns, tableSize.width);
  }, [columns, tableSize.width]);

  // calculate current sizes
  useEffect(() => {
    if (curCol) {
      realSizesRef.current = calculateRealSizes(columns, tableRef.current!);
    }

    if (!curCol) {
      prevTableWidthRef.current = tableSize.width;
      prevRealSizesRef.current = calculateRealSizes(columns, tableRef.current!);
    }
  }, [curCol, columns]);

  useEffect(() => {
    if (
      !curCol ||
      !isResizePossible(curCol, pageXDiff!, columns, minMaxSizesRef.current!, realSizesRef.current!)
    ) {
      return;
    }

    const newSizes = calculateCurrentSizes(
      curCol,
      pageXDiff!,
      columns,
      minMaxSizesRef.current!,
      realSizesRef.current!,
      tableSize
    );

    setSizes(newSizes);
  }, [curCol, columns, pageXDiff]);

  // solution to fix small sizes of columns which dom sometimes returns
  const sizesFixed: any = useMemo(() => {
    const columnsMap = keyBy(columns, "id");

    if (!sizes || sizes.every((s) => getWidth(s.width) >= 60)) {
      return sizes;
    }

    return sizes.map((node) => {
      const minWidth = Number.parseInt(String(columnsMap[(node as any).id]?.minWidth)) || 60;
      return new SizeNode((node as any).id, Math.max(node.width, minWidth));
    });
  }, [sizes, columns]);

  return {
    onMouseDown,
    sizes: sizesFixed,
    isResizeActive: Boolean(curCol),
  };
}

function getWidth(val: number | string) {
  if (typeof val === "number") {
    return val;
  }

  return Number.parseFloat(String(val).replace("minmax(", ""));
}
