import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  gridClasses,
  GridFilterOperator,
  GridAlignment,
} from "@mui/x-data-grid-pro";
import { GridFilterAutocomplete } from "./organisms/GridFilterAutocomplete";
import { CHGridColDef } from "./types";

export const CHECKBOX_COLUMN_ID = GRID_CHECKBOX_SELECTION_COL_DEF.field;

export const customGridClasses = {
  zeroNumberCell: `${gridClasses.cell}--zero`,
  highlightCell: `${gridClasses.cell}--highlight`,
  actionsHeader: `${gridClasses.columnHeader}--actions`,
};

export const CHECKBOX_COLUMN_CONFIG: CHGridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  width: 34,
  maxWidth: 34,
  minWidth: 34,
  flex: 0,
  align: "left" as GridAlignment,
  headerAlign: "left" as GridAlignment,
  hideable: false,
};

export const CHECKBOX_COLUMN_CONFIG_NEW_STYLE: CHGridColDef = {
  ...CHECKBOX_COLUMN_CONFIG,
  width: 60,
  maxWidth: 60,
  minWidth: 60,
};

export const GRID_DETAIL_PANEL_TOGGLE_COLUMN_CONFIG: CHGridColDef = {
  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  width: 38,
  maxWidth: 38,
  minWidth: 38,
};

export const GRID_OPERATORS: Record<"notContains", GridFilterOperator> = {
  notContains: {
    label: "does not contain",
    value: "doesNotContain",
    getApplyFilterFn: () => () => true,
    InputComponent: GridFilterAutocomplete,
  },
};

export const FIELDS_ALWAYS_IN_BEGINNING_ORDERED = [
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
  GRID_CHECKBOX_SELECTION_COL_DEF.field,
];

export const PICKED_ROW_CLASS_NAME = "pickedRow";
export const DISABLED_ROW_CLASS_NAME = "rowDisabled";
