import axios from "axios";
import { ISalesforceTenant } from "./interfaces";
import { ExternalAuth } from "./ExternalAuth";

class SalesforceAuth extends ExternalAuth<ISalesforceTenant> {
  private tenant?: ISalesforceTenant;

  isAuthorized() {
    return Boolean(this.tenant);
  }

  getUser = () => {
    if (this.isAuthorized()) {
      return this.tenant!;
    }

    return axios
      .get<ISalesforceTenant>("/salesforce/login/info", {
        meta: {
          ignoreUnauthorized: true,
        },
      } as any)
      .then((response) => {
        this.tenant = response.data;
        return this.tenant!;
      });
  };

  login(_token?: string, isSandbox?: boolean) {
    const sandbox: boolean = isSandbox !== undefined && isSandbox === true;
    return this.authorize(
      "/salesforce/login" + (sandbox ? "?sandbox" : ""),
      "Salesforce Login Consent"
    ).then(this.getUser);
  }

  logout() {
    return axios.get<ISalesforceTenant>("/salesforce/logout").then((response) => {
      this.tenant = undefined;
      return response;
    });
  }
}

export const salesforceAuth = new SalesforceAuth();
