import React, { useEffect, useMemo, useRef, useState } from "react";
import { isEqual, keyBy } from "lodash";
import { Chip } from "modules/table";
import { useGrid } from "../useGrid";
import { getGridFilterItemLabel } from "../filters";
import { GridFieldFilter, GridFilters } from "../filterTypes";
import { createLabelFromField } from "../normalizeColumnDefinitions";

export function useGridFiltersChips({ timezone }: { timezone?: string }) {
  const { filters, setFilters, columns } = useGrid();

  const onClearFilter = (field: string) => {
    const newFilters = { ...filters };
    delete newFilters[field];

    setFilters(newFilters);
  };

  const columnById = useMemo(() => keyBy(columns, (column) => column.field), [columns]);
  const lastChangedFilter = useLastChangedFilters(filters);

  return Object.values(filters).map((filter) => (
    <Chip
      key={filter.field}
      name={filter.field}
      prefix={columnById[filter.field].headerName || createLabelFromField(filter.field)}
      value={filter}
      label={() => getLabel(filter, { timezone })}
      isClearAll={true}
      isDefault={false}
      showEmpty={false}
      animateMount={lastChangedFilter.includes(filter.field)}
      shouldJoinLabel={false}
      onDelete={() => onClearFilter(filter.field)}
    />
  ));
}

function getLabel(filter: GridFieldFilter, options: { timezone?: string }) {
  return filter.items.map((item) =>
    item.itemType === "condition" ? (
      <b key={item.id}> {item.operator} </b>
    ) : (
      getGridFilterItemLabel(filter.type, item, options)
    )
  );
}

function useLastChangedFilters(filters: GridFilters): string[] {
  const previousFilters = useRef(filters);
  const [lastChangedFilters, setLastChangedFilters] = useState<string[]>([]);

  useEffect(() => {
    const previous = previousFilters.current;
    const current = filters;

    const changedFilters = Object.keys(current).filter(
      (key) => !isEqual(previous[key], current[key])
    );

    setLastChangedFilters(changedFilters);
    previousFilters.current = current;
  }, [filters]);

  return lastChangedFilters;
}
