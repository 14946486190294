import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid-pro";
import { useGridCollection } from "modules/grid/useGridCollection";
import { api } from "api";
import { directory_EndpointUserFieldEnum, directory, types } from "api/gen";
import { GridFilters } from "modules/grid/filterTypes";
import { transformGridFiltersToCEL } from "modules/grid/filters";
import { logEvent, eventsConstants } from "../analytics/Analytics";
import { notification } from "../notification";
import { messages } from "../users/messages";
import { Directory, DirectoryState, DirectoryUser } from "./workOsTypes";
import { fetchMappedUsers } from "./requests";
import { MappedUser, UserMappingColumnField } from "./types";

export const UserDirectoryKey = "user-directory";
const UserDirectoryLinkKey = "user-directory-link";
const UserDirectoryListKey = "user-directory-list";
const UserDirectoryFieldsEmpty = "user-directory-fields-empty";
const MappedUsersKey = "mapped-users";

const ONE_HOUR_MS = 60 * 1000 * 60;

interface DirectoriesPage {
  nextPage: number;
  result: Directory[];
  total?: number;
}

export const useEmptyDirectoryFields = () => {
  return useQuery({
    queryKey: [UserDirectoryFieldsEmpty],

    queryFn: async () => {
      try {
        return await api.irm.Service.GetRiskyUserFieldsValusCount({}).then((res) => {
          if (!res.fields) {
            return [];
          }
          return Object.keys(res.fields).filter((key) => res.fields![key] === 0);
        });
      } catch {
        return [];
      }
    },

    retry: 1,
    gcTime: ONE_HOUR_MS,
    staleTime: ONE_HOUR_MS,
    refetchOnMount: false,
  });
};

export function useDirectoryUser(
  args: {
    id?: string | number;
    local_user_name?: string;
    local_user_sid?: string;
    endpoint_ids?: string[];
    endpoint_user_ids?: string[];
  },
  enabled: boolean = true
) {
  let params: directory.FindParams[][] = [];
  if (args.id) {
    params.push([
      {
        field: directory_EndpointUserFieldEnum.EndpointUserFieldID,
        value: String(args.id),
      },
    ]);
  } else if (args.endpoint_user_ids) {
    params = args.endpoint_user_ids.map((endpoint_user_id): directory.FindParams[] => [
      {
        field: directory_EndpointUserFieldEnum.EndpointUserFieldEndpointUserID,
        value: endpoint_user_id,
      },
    ]);
  } else if (args.local_user_sid || args.local_user_name) {
    const local_id = args.local_user_sid || args.local_user_name!;
    const field = args.local_user_sid
      ? directory_EndpointUserFieldEnum.EndpointUserFieldSID
      : directory_EndpointUserFieldEnum.EndpointUserFieldLocalUserName;
    const endpointIds = [...(args.endpoint_ids || [])].filter((eid) => eid?.length); // filter out empty endpoint_ids
    params = endpointIds.map((endpoint_id): directory.FindParams[] => [
      {
        field: field,
        value: local_id.toLocaleLowerCase(),
      },
      { field: directory_EndpointUserFieldEnum.EndpointUserFieldEndpointID, value: endpoint_id },
    ]);
    if (params.length === 0 && args.local_user_name) {
      // no endpoint_ids are associated with the user. we cannot lookup a user only by local_user_name.
      // this is typically the case for cloud sensors. treat local_user_name as email address.
      params.push([
        {
          field: directory_EndpointUserFieldEnum.EndpointUserFieldEmail,
          value: args.local_user_name,
        },
      ]);
    }
  }
  return useQuery({
    queryKey: [UserDirectoryKey, args],

    queryFn: async () => {
      const usersResponses = await Promise.allSettled(
        params.map(async (filter) => {
          return api.directory.DirectoryService.FindUser({ filter }).then((res) => {
            const user = res.user;
            return {
              ...user,
              emails: user?.emails?.map((email) => email?.value ?? ""),
            } as DirectoryUser;
          });
        })
      );

      if (usersResponses.every((res) => res.status === "rejected")) {
        return Promise.reject("No user found");
      }
      const userIdsSet = new Set<string>();

      const responses = usersResponses
        .filter((res) => {
          if (res.status === "rejected" || !res.value) {
            return false;
          }

          if (userIdsSet.has(res.value.id)) {
            return false;
          }
          userIdsSet.add(res.value.id);
          return true;
        })
        .map((res) => (res as { value: DirectoryUser }).value)
        .sort((a, b) => {
          // if multiple users are returned, make sure a directory one is first
          if (Boolean(a?.directory_id) && !b?.directory_id) {
            return -1;
          } else if (Boolean(b?.directory_id) && !a?.directory_id) {
            return 1;
          } else {
            return a.id.localeCompare(b.id);
          }
        });
      return responses;
    },

    enabled: enabled && params.length > 0,
    retry: 0,
  });
}

export function useDirectoryAdminCenterLink() {
  return useQuery({
    queryKey: [UserDirectoryLinkKey],
    queryFn: () => api.directory.DirectoryService.GenPortalLink({}),
    refetchInterval: 60 * 1000,
  });
}

export interface DirectoriesRequestParams {
  sort: GridSortModel;
}

const DIRECTORY_HIDDEN_FIELDS = [
  "created_at",
  "updated_at",
  "primary_address_primary",
  "primary_address_type",
  "organization_id",
  "id",
  "idp_id",
];
export function useDirectories(params: DirectoriesRequestParams) {
  const { data: directories, isFetching, isLoading } = useDirectoriesGrid();

  const sortedDirectories = useMemo(() => {
    const sort = params.sort?.[0] as GridSortItem;
    return [...(directories ?? [])].sort((dirA, dirB) => {
      const sortBy = sort?.field as keyof Directory;
      let res = 0;
      if (sortBy === "updated_at" || sortBy === "created_at") {
        res = Number(new Date(dirA[sortBy])) - Number(new Date(dirB[sortBy]));
      } else if (sortBy === "name") {
        res = String(dirA.type).localeCompare(String(dirB.type));
      } else if (sortBy === "user_count") {
        res = dirA.user_count - dirB.user_count;
      } else {
        res = String(dirA[sortBy]).localeCompare(String(dirB[sortBy]));
      }
      return sort?.sort === "desc" ? -res : res;
    });
  }, [directories, params.sort]);

  const filteredDirectories = useMemo(
    () =>
      sortedDirectories.map((directory) => {
        return {
          ...directory,
          user_field_metrics: directory.user_field_metrics?.filter(
            (metric) => !DIRECTORY_HIDDEN_FIELDS.includes(metric.ch_field_name)
          ),
        };
      }),
    [sortedDirectories]
  );

  return {
    directories: filteredDirectories,
    isLoading: isLoading || isFetching,
  };
}

export function useDirectoriesGrid() {
  const query = useGridCollection<types.FullDirectory>({
    key: [UserDirectoryListKey],
    fetchData: async () => {
      const res = await api.directory.DirectoryService.ListDirectories({});

      return {
        result: res.directories ?? [],
        nextPage: undefined,
      };
    },
    staleTime: 2 * 60 * 1000, // 2 minutes
    enabled: true,
  });

  return query;
}

export type UsersCount = {
  directoryId: string;
  usersCount: number;
};

export function useDeleteDirectory() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      let oldStatus: string = "";

      function setStatus(state: string) {
        queryClient.setQueryData(
          [UserDirectoryListKey],
          (prevState: { pageParams: any[]; pages: DirectoriesPage[] }) => {
            return {
              ...prevState,
              pages: prevState.pages.map((page: DirectoriesPage) => {
                return {
                  ...page,
                  result: page.result.map((directory) => {
                    if (directory.id === id) {
                      oldStatus = directory.state;
                      return {
                        ...directory,
                        state,
                      };
                    }

                    return directory;
                  }),
                };
              }),
            };
          }
        );
      }

      setStatus(DirectoryState.Deleting);

      try {
        await api.directory.DirectoryService.DeleteDirectory({ id });
        return id;
      } catch (err) {
        setStatus(oldStatus);
        throw err;
      }
    },
    async onSuccess(idToDelete) {
      queryClient.setQueryData(
        [UserDirectoryListKey],
        (prevState: { pageParams: any[]; pages: DirectoriesPage[] }) => {
          return {
            ...prevState,
            pages: prevState.pages.map((page: DirectoriesPage) => {
              return {
                ...page,
                result: page.result.filter((directory) => {
                  return directory.id !== idToDelete;
                }),
              };
            }),
          };
        }
      );
      logEvent(eventsConstants.preferences.directory.delete);

      notification.open({
        message: messages.notifications.getDirectoryNotificationText(
          messages.notifications.statuses.removed
        ),
      });
    },
    onError(error: any) {
      notification.error({ message: error.message });
    },
  });
}

export interface UserMappingRequestParams {
  filters: GridFilters;
  sort: GridSortModel;
}

export function useUserMappingGrid(params: UserMappingRequestParams) {
  const filter = transformGridFiltersToCEL(params.filters, {
    [UserMappingColumnField.Status]: (item, defaultTransformer) => {
      return defaultTransformer(UserMappingColumnField.Status, { ...item, case_sensitive: true });
    },
  });
  const sort = params.sort.slice(0, 1).map((item: GridSortItem) => ({
    by: item.field,
    desc: item.sort === "desc",
  }))?.[0];

  const query = useGridCollection<MappedUser | null>({
    key: [MappedUsersKey, params],
    fetchData: async (offset) => {
      const res = await fetchMappedUsers({
        sort,
        filter,
        page_size: 50,
        offset: offset || 0,
      });
      const nextPage =
        res.records && offset + res.records.length < res.total
          ? offset + res.records.length
          : undefined;

      return {
        total: res.total,
        result: res.records ?? [],
        nextPage,
      };
    },
    staleTime: 2 * 60 * 1000, // 2 minutes
    enabled: true,
  });

  return query;
}
