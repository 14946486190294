import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { FiltersIcon } from "assets/icons";
import { useEvent, useUpdate } from "react-use";
import { debounce } from "lodash";
import { Link, Popover, Typography } from "@mui/material";
import { usePopover } from "libs/hooks";
import { HStack } from "ui/atoms";
import { space } from "ui/vars";
import { PopoverFilters } from "./PopoverFilters";

export function TruncateFilters({
  children,
  clearAll,
  hideClearAll,
  dataId = undefined,
  fixedElements = 200,
  showPrefix = true,
  customPrefix,
}: any) {
  const ref = useRef<HTMLDivElement>(null);
  const [cutBefore, setCutBefore] = useState(0);
  const forceUpdate = useUpdate();
  const forceUpdateDebounced = useMemo(() => debounce(forceUpdate, 100), []);

  useEvent("resize", forceUpdateDebounced, window);

  const childrenArray = React.Children.toArray(children).filter((c) => Boolean(c));
  const [comp, actions] = usePopover((api) => (
    <Popover {...api}>
      <PopoverFilters>
        {(childrenArray as React.ReactElement[])
          .slice(0, cutBefore)
          .map((el) => React.cloneElement(el, { animateMount: false }))}
        <Link color="textSecondary" style={{ lineHeight: 1 }} onClick={clearAll}>
          Clear All
        </Link>
      </PopoverFilters>
    </Popover>
  ));
  useLayoutEffect(() => {
    if (actions.open && cutBefore > childrenArray.length) {
      actions.onClose();
    }
  }, [childrenArray.length, actions.open]);

  // filters label + clear all + +n button
  // todo watch window resize
  useLayoutEffect(() => {
    const node = ref.current!;
    let availableWidth = node.clientWidth - fixedElements;
    const { childNodes } = node;
    let i;
    for (i = childNodes.length - 1; i >= 0; i--) {
      const element = childNodes[i] as HTMLDivElement;
      if (element) {
        const style = window.getComputedStyle(childNodes[i] as HTMLDivElement);
        const minWidth = parseInt(style.minWidth || "0");
        const itemSize = minWidth || element.clientWidth || 0;
        availableWidth -= itemSize;
        if (availableWidth < 0) {
          // If no space left after we checked final element, we need to hide final element
          if (i === 0) {
            i = 1;
          }
          break;
        }
      }
    }
    setCutBefore(i);
  });
  const filtersNode =
    showPrefix &&
    (customPrefix || (
      <HStack space="1" align="center" style={{ marginRight: space["1"], flexShrink: 0 }}>
        <FiltersIcon style={{ fontSize: 20, color: "#545454" }} />
        <Typography
          style={{ fontWeight: 400, textTransform: "capitalize" }}
          variant="h6"
          data-fs="unmask"
        >
          Filters applied:
        </Typography>
      </HStack>
    ));
  return (
    <>
      <HStack
        space="1"
        align="center"
        style={{ height: 0, margin: 0, overflowY: "hidden" }}
        ref={ref}
      >
        {filtersNode}
        {children}
      </HStack>
      <HStack space="1" align="center" style={{ height: 30 }} data-testid={dataId}>
        {filtersNode}
        {cutBefore > 0 && (
          <Link
            color="textPrimary"
            onClick={(e: any) => actions.onOpen(e.currentTarget)}
            data-testid="filters/show-more"
            className="flex-shrink-0"
          >
            + {cutBefore} more
          </Link>
        )}
        {cutBefore > 0 ? childrenArray.slice(cutBefore) : childrenArray}

        {cutBefore > 0 && comp}
        <Link
          onClick={clearAll}
          color="textSecondary"
          style={{
            display: hideClearAll ? "none" : "block",
            lineHeight: 1.5,
            marginLeft: space[3],
            flexShrink: 0,
          }}
          data-fs="unmask"
        >
          Clear All
        </Link>
      </HStack>
    </>
  );
}
