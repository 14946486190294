import { Services, ServiceType } from "app/externalServicesTypes";
import { mockErrorHandler, setupMockLogger } from "shared/mocks";
let services: Promise<{ [T in keyof Services]: Promise<Services[T]> }>;

/// we use global parameter to not load unused services in tests
export async function resolveService<K extends ServiceType>(serviceName: K): Promise<Services[K]> {
  // we have to do it here because in out tests we do not import setupExternal services.
  if (process.env.NODE_ENV === "test") {
    services =
      services ||
      Promise.resolve({
        logger: setupMockLogger(),
        errorReporter: mockErrorHandler,
      } as any);
  }

  if (!services) {
    const msg = "services are not set, failed to resolve service: " + serviceName;
    return Promise.reject(msg);
  }

  const logError = (await (await services).errorReporter).report;

  const service = await ((await services) as any)[serviceName];
  if (!service) {
    logError("Failed to locate service: " + serviceName);
    return Promise.reject("no such service");
  }

  return await service;
}

export async function setServices(s: typeof services) {
  if (await services) {
    throw new Error("services are already set");
  }
  services = s;
}
