import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";

const MARGIN_OF_ERROR = 5; // px

export interface LimitedListProps<T extends React.ReactNode> {
  items: T[];
  rowsLimit: number;
  gapSize: number;
  renderItem: (_: T) => React.ReactNode;
  renderMore: (_: T[]) => React.ReactNode;
  className?: string;
}

export function LimitedList<T extends React.ReactNode>({
  items,
  rowsLimit = 2,
  gapSize = 8,
  renderItem,
  renderMore,
  className,
}: LimitedListProps<T>) {
  const containerRef = useRef<HTMLUListElement>(null);
  const [isCalculating, setIsCalculating] = useState(true);
  const [visibleItemsCount, setVisibleItemsCount] = useState(items.length);
  const [showMore, setShowMore] = useState(items.length > 0);

  // Ref to store stable UUIDs for each item
  const itemIds = useRef<Map<T, string>>(new Map());
  // Ensure each item has a stable UUID
  items.forEach((item) => {
    if (!itemIds.current.has(item)) {
      itemIds.current.set(item, uuidv4());
    }
  });

  const width = containerRef.current?.parentElement?.offsetWidth || 0;

  const listClassNames = twMerge(
    "flex flex-row flex-wrap items-center h-fit-content gap-s p-0 m-0 list-none",
    className
  );

  const reset = useCallback(() => {
    setIsCalculating(items.length > 0);
    setVisibleItemsCount(items?.length || 0);
    setShowMore(items.length > 0);
  }, [items]);

  useEffect(() => {
    window.addEventListener("resize", reset);

    return () => window.removeEventListener("resize", reset);
  }, [reset]);

  useEffect(() => {
    reset();
  }, [rowsLimit, items, reset]);

  useLayoutEffect(() => {
    if (!isCalculating) {
      return;
    }

    const children = containerRef.current?.children;
    const childrenArr = Array.from(children || []);

    const moreButtonWidth = childrenArr.pop()?.clientWidth || 0;

    let currentRowWidth = 0;
    let currentRowNumber = 1;
    let isLastRow = rowsLimit === 1;
    for (let i = 0; i < childrenArr.length; i++) {
      const child = childrenArr[i] as HTMLElement;
      const childWidth = child.clientWidth;
      const needsMoreButton = i < items.length - 1;

      currentRowWidth += (currentRowWidth ? gapSize : 0) + childWidth;

      const currentRowWidthIfLastVisibleItem =
        currentRowWidth + (needsMoreButton && isLastRow ? gapSize + moreButtonWidth : 0);

      if (currentRowWidthIfLastVisibleItem + MARGIN_OF_ERROR > width) {
        if (isLastRow) {
          setShowMore(i - 1 < items.length - 1);
          setVisibleItemsCount(i);
          setIsCalculating(false);

          return;
        }

        ++currentRowNumber;
        currentRowWidth = childWidth;
        isLastRow = currentRowNumber === rowsLimit;
      }
    }

    setShowMore(false);
    setVisibleItemsCount(items.length);
    setIsCalculating(false);
  }, [
    items,
    rowsLimit,
    isCalculating,
    setIsCalculating,
    setVisibleItemsCount,
    setShowMore,
    gapSize,
    width,
  ]);

  if (!items.length) {
    return null;
  }

  return (
    <ul
      className={listClassNames}
      ref={containerRef}
      style={{
        width,
        position: isCalculating ? "absolute" : "relative",
        left: isCalculating ? 1000000 : undefined,
      }}
    >
      {items.slice(0, visibleItemsCount).map((item) => (
        <li key={itemIds.current.get(item)}>{renderItem(item)}</li>
      ))}
      {showMore && <>{renderMore(items.slice(isCalculating ? 1 : visibleItemsCount))}</>}
    </ul>
  );
}
