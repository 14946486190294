import React, { ReactNode } from "react";
import { PartialNode, ItemElement, ItemProps } from "../types";

function Item(): ReactNode {
  return null;
}

Item.getCollectionNode = function* getCollectionNode<T>(
  props: ItemProps<T>,
  context: any
): Generator<PartialNode<T>> {
  const { childItems, title, children } = props;

  const rendered = props.title || props.children;
  const textValue =
    props.textValue || (typeof rendered === "string" ? rendered : "") || props["aria-label"] || "";

  // suppressTextValueWarning is used in components like Tabs, which don't have type to select support.
  if (!textValue && !context?.suppressTextValueWarning) {
    console.warn(
      "<Item> with non-plain text contents is unsupported by type to select for accessibility. Please add a `textValue` prop."
    );
  }

  yield {
    "type": "item",
    "props": props,
    rendered,
    textValue,
    "aria-label": props["aria-label"],
    "hasChildNodes": hasChildItems(props),
    *"childNodes"() {
      if (childItems) {
        for (const child of childItems) {
          yield {
            type: "item",
            value: child,
          };
        }
      } else if (title) {
        const items: Array<PartialNode<T>> = [];
        React.Children.forEach(children, (child) => {
          items.push({
            type: "item",
            element: child as ItemElement<T>,
          });
        });

        yield* items;
      }
    },
  };
};

function hasChildItems<T>(props: ItemProps<T>) {
  if (typeof props.hasChildItems !== "undefined") {
    return props.hasChildItems;
  }

  if (props.childItems) {
    return true;
  }

  if (props.title && React.Children.count(props.children) > 0) {
    return true;
  }

  return false;
}

// We don't want getCollectionNode to show up in the type definition
const _Item = Item as <T>(props: ItemProps<T>) => JSX.Element;
export { _Item as Item };
