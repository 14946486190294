import { datasetDefaults, policyDefaults, createTypeEnum } from "shared/dashboard";
import { riskydashboard, types } from "api";
import { useFilters } from "modules/table";

export const editConfig = {
  editingDataset: {
    queryKey: "editingDataset",
    defaultValue: {} as {
      id: string | "new" | "search";
      values: riskydashboard.DatasetDTO;
      templateId?: string;
      viewMode?: boolean;
    },
  },
  editingPolicy: {
    queryKey: "editingPolicy",
    defaultValue: {} as {
      id: string | "new" | "search";
      values: types.Category;
      templateId?: string;
      viewMode?: boolean;
    },
  },
} as const;

export function useLocationEditFilters() {
  const {
    editingPolicy,
    editingDataset,
    setEditingDataset: setEditingDatasetOriginal,
    setEditingPolicy,
  } = useFilters({
    filters: editConfig,
    queryKeyPrefix: "",
    initialValue: {},
  });

  const setEditingDataset = (
    editData:
      | { id: string; values: riskydashboard.DatasetDTO; templateId?: string; viewMode?: boolean }
      | undefined
  ) => {
    if (!editData) {
      return setEditingDatasetOriginal(editData);
    }
    const copy = { ...editData.values };
    // @ts-ignore
    delete copy.severity;
    setEditingDatasetOriginal({
      id: editData.id,
      values: copy,
      templateId: editData.templateId,
      viewMode: editData.viewMode,
    });
  };

  return {
    policyEdit: {
      id: editingPolicy?.id,
      // means it's either new or search or edit
      isEditing: Boolean(editingPolicy?.id),
      // means that we are editing entity that exists
      isEdit:
        editingPolicy?.id !== "new" && editingPolicy?.id !== "search" && Boolean(editingPolicy?.id),
      isNew: editingPolicy?.id === "new",
      viewMode: Boolean(editingPolicy.viewMode),
      isSearch: editingPolicy?.id === "search",
      templateId: editingPolicy?.templateId,
      editingPolicyValues: editingPolicy?.values,
      convertToEdit() {
        let values = editingPolicy?.values;
        if (editingPolicy?.values) {
          values = { ...editingPolicy?.values, dataset_ids: [] };
        }

        // TODO Find the root cause of this and fix it there
        if (
          values?.user_query?.rules?.[0]?.conditions?.[0]?.values &&
          Array.isArray(values.user_query.rules[0].conditions[0].values)
        ) {
          values.user_query.rules[0].conditions[0].values =
            // When no value is selected in the search form, this array contains an object
            // `{ value: undefined }` which causes a validation error when saving the policy without
            // selecting a user risk group in the policy form.
            values.user_query.rules[0].conditions[0].values.filter((v: any) => v.value);
        }

        setEditingPolicy({
          id: createTypeEnum.new,
          values: values,
        });
      },
      setEditingPolicyValues(v: types.Category) {
        setEditingPolicy({
          id: editingPolicy?.id,
          values: v,
          templateId: editingPolicy?.templateId,
        });
      },
      startEditingPolicy(id: string, v: types.Category = policyDefaults as any, viewMode = false) {
        setEditingPolicy({
          id: id,
          values: v,
          viewMode: viewMode,
        });
      },
      setViewMode(viewMode: boolean) {
        setEditingPolicy({
          ...editingPolicy,
          viewMode,
        });
      },
      finishEditingPolicy() {
        setEditingPolicy(undefined);
      },
      cancelEditingPolicy() {
        setEditingPolicy(undefined);
      },
    },
    datasetEdit: {
      isEdit:
        editingDataset?.id !== "new" &&
        editingDataset?.id !== "search" &&
        Boolean(editingDataset.id),
      isEditing: Boolean(editingDataset.id),
      viewMode: Boolean(editingDataset.viewMode),
      setViewMode(viewMode: boolean) {
        setEditingDataset({
          ...editingDataset,
          viewMode,
        });
      },
      isNew: editingDataset.id === "new",
      isSearch: editingDataset.id === "search",
      id: editingDataset?.id,
      editingValues: editingDataset?.values,
      templateId: editingDataset?.templateId,
      setEditingValues(v: riskydashboard.DatasetDTO) {
        setEditingDataset({
          id: editingDataset.id,
          values: v,
          templateId: editingDataset.templateId,
          viewMode: editingDataset.viewMode,
        });
      },
      startEditing(
        id: string,
        v: riskydashboard.DatasetDTO = datasetDefaults as any,
        viewMode = false
      ) {
        setEditingDataset({
          id: id,
          values: v,
          viewMode: viewMode,
        });
      },
      convertToEdit() {
        let values = editingDataset?.values;
        if (editingDataset?.values) {
          values = { ...editingDataset?.values, category_ids: [] };
        }

        setEditingDataset({
          id: createTypeEnum.new,
          values,
          viewMode: false,
        });
      },
      finishEditing() {
        setEditingDataset(undefined);
      },
      cancelEditing() {
        setEditingDataset(undefined);
      },
    },
  };
}
