// eslint-ignore
import bowser from "bowser/bowser";
import { forwardRef } from "react";

export function removeWebPrefix(label: string): string {
  const re = /https?:\/\/(www.)?(.*) ?/;
  const matches = re.exec(label);
  if (matches && matches[2]) {
    return matches[2];
  }
  return label;
}

// tslint:disable-next-line:no-empty
export function noop(): void {}

export function noopFilter(): boolean {
  return true;
}

export function isValidBrowser(browsers: any) {
  let isValid = false;

  Object.keys(browsers).forEach((key) => {
    if (bowser[key] && browsers[key] < bowser.version) {
      isValid = true;
    }
  });

  return isValid;
}

export function formatValidateEmailList(array: any) {
  if (array.length > 1) {
    return `Lines ${array.join(", ")} contain invalid email`;
  }
  if (array.length > 0) {
    return `Line ${array.join("")} contains invalid email`;
  }
  return "";
}
// returns indexes of invalid emails and empty array if it's valid
export function validateEmailList(string = "") {
  const regex =
    /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/;
  const result = string.split("\n");
  const resultEmails: number[] = [];
  for (let i = 0; i < result.length; i++) {
    if (!regex.test(result[i])) {
      resultEmails.push(i + 1);
    }
  }
  return resultEmails;
}

function validateEmails(string = "") {
  const regex =
    /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/;
  const result = string.replace(/\s/g, "").split(/,|;/);
  for (let i = 0; i < result.length; i++) {
    if (!regex.test(result[i])) {
      return false;
    }
  }
  return true;
}

export function isValidEmail(address: string, allowMultiple: boolean): string | undefined {
  if (!allowMultiple) {
    // Fast lookup to detect possibility of multiple emails. Only checks for unquoted delimiters
    const matches = address.match(/[\s,;]+(?=([^"]*"[^"]*")*[^"]*$)/g);
    if (matches && matches.length > 0) {
      return "Does not support more than 1 address";
    }
  }

  // Relaxed version of RFC 5322. Should match 99.9% emails
  // eslint-disable-next-line no-useless-escape
  if (!validateEmails(address)) {
    return "Not a valid email address";
  }

  return undefined;
}

// Old implemetation
export function formatCount(n: number, subject: string) {
  return `${n} ${subject}${n > 1 ? "s" : ""}`;
}

// Choose singular or plural. (Good for translation)
export function formatByCount(n: number, singular: string, plural: string) {
  return `${n > 1 ? plural : singular}`;
}

export function formatCountTitle(n: number, subject: string) {
  return `${subject}${Math.abs(n) !== 1 ? "s" : ""}`;
}

export function compact<T>(
  array: Array<T | null | undefined | false | "" | 0> | null | undefined
): T[] {
  return (array?.filter(Boolean) as T[]) || [];
}

type TimeType = {
  time?: string | null;
};

export const sortByTime = <T extends TimeType>(data: T[], reverse = false) =>
  data?.sort(
    (a, b) =>
      (new Date(a.time || "0").getTime() < new Date(b.time || "0").getTime() ? 1 : -1) *
      (reverse ? -1 : 1)
  );

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type FixedForwardRef = <T, P = {}>(
  render: (props: P, ref: React.ForwardedRef<T>) => React.ReactNode
) => (props: P & React.RefAttributes<T>) => React.ReactElement | null;

export const fixedForwardRef = forwardRef as FixedForwardRef;

export function ucfirst(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
