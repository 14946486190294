import React, { useCallback, useContext, useMemo } from "react";
import { useCurrentUserId } from "shared/auth-hooks";
import { cloneDeep, merge, pick } from "lodash";
import { useHistoryParam } from "shared";
import { z } from "zod";
import {
  UISettingsTableEntity,
  useUISettings,
  useUpdateUISettings,
} from "shared/ui-settings/useUISettings";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid-pro";
import { LayoutLoader } from "ui";
import { useStateWithSetters, StateWithSetters } from "libs/hooks";
import { getDefaultState } from "./state";
import { CHGridColDef, GridStateFields } from "./types";
import { columnOrderWithoutFixed } from "./utils";

export const gridStateSchema = z.object({
  columnVisibilityModel: z.record(z.boolean()),
  search: z.string(),
  sortModel: z.array(
    z.object({
      field: z.string(),
      sort: z.union([z.literal("asc"), z.literal("desc")]),
    })
  ),
  pinnedColumns: z.object({
    left: z.array(z.string()),
    right: z.array(z.string()),
  }),
  pickedRow: z.union([z.string(), z.number(), z.undefined()]),
  columnsOrder: z.array(z.string()),
  columnsWidths: z.record(z.number()),
  density: z.union([z.literal("compact"), z.literal("standard"), z.literal("comfortable")]),
  filters: z.record(z.any()),
});

export const GridStateContext = React.createContext<{
  state: StateWithSetters<GridStateFields>;
  onStateChange: (state: GridStateFields) => any;
  columns: CHGridColDef[];
  noDataFields?: string[];
} | null>(null);

export const useGridState = () => useContext(GridStateContext)!;

export interface GridStateProviderProps {
  name: UISettingsTableEntity;
  columns: CHGridColDef[];
  children: React.ReactNode;
  loader?: React.ReactNode;
  defaultState?: Partial<GridStateFields>;
  noDataFields?: string[];
  stateOverrides?: Partial<GridStateFields>;
  mapOldIdToNew?: Record<string, string>;
}

function mapGridStateOldIdsToNew(
  state: GridStateFields | null | undefined,
  mapOldIdToNew?: Record<string, string>
) {
  if (!state || !mapOldIdToNew) {
    return state;
  }
  const { columnsOrder, columnVisibilityModel } = state;

  return {
    ...state,
    columnsOrder: columnsOrder.map((id) => mapOldIdToNew[id] ?? id),
    columnVisibilityModel: Object.fromEntries(
      Object.entries(columnVisibilityModel).map(([id, visible]) => [
        mapOldIdToNew[id] ?? id,
        visible,
      ])
    ),
  };
}

// Manages state persistence
// Priority in which state is applied: default state -> api store -> session store -> browser history
// Ensures that grid has state already loaded when rendered
export function GridStateProvider({
  name: gridName,
  children,
  columns,
  loader = <LayoutLoader />,
  noDataFields,
  defaultState: providedDefaultState,
  stateOverrides,
  mapOldIdToNew = {},
}: GridStateProviderProps) {
  const userId = useCurrentUserId()!;
  const keyParams = useMemo(
    () => ({
      gridName,
      userId,
    }),
    [gridName, userId]
  );
  const { data, isLoading } = useGridStoredState(keyParams);
  const { mutate: updateStoredState } = useUpdateGridStoredState(keyParams);

  const storedState = useMemo(
    () => mapGridStateOldIdsToNew(data?.settings, mapOldIdToNew),
    [data?.settings, mapOldIdToNew]
  );
  const defaultState = useMemo(
    () => ({ ...getDefaultState(columns), ...providedDefaultState }),
    [columns, providedDefaultState]
  );

  const sessionState = useMemo(
    () => mapGridStateOldIdsToNew(getGridStateFromSession(keyParams), mapOldIdToNew),
    [keyParams, mapOldIdToNew]
  );

  const getColumnsOrder = useCallback(() => {
    const initialColumnsState =
      sessionState?.columnsOrder ?? storedState?.columnsOrder ?? defaultState?.columnsOrder;
    const colOrder = columnOrderWithoutFixed(initialColumnsState);
    return colOrder.map((id) => mapOldIdToNew[id] ?? id);
  }, [
    defaultState?.columnsOrder,
    mapOldIdToNew,
    sessionState?.columnsOrder,
    storedState?.columnsOrder,
  ]);

  const initialState = useMemo(() => {
    const columnVisibilityModel = {
      ...merge(
        defaultState?.columnVisibilityModel,
        storedState?.columnVisibilityModel,
        sessionState?.columnVisibilityModel
      ),
      ...stateOverrides?.columnVisibilityModel,
    } as GridColumnVisibilityModel;
    return {
      ...defaultState,
      ...storedState,
      ...sessionState,
      columnsOrder: getColumnsOrder(),
      columnVisibilityModel,
      sortModel: [storedState?.sortModel, sessionState?.sortModel].reduce(
        // Use the first non-empty sort model
        (acc, sortModel) => (sortModel?.length ? sortModel : acc),
        defaultState.sortModel
      ) as GridSortModel,
    };
  }, [
    defaultState,
    storedState,
    sessionState,
    stateOverrides?.columnVisibilityModel,
    getColumnsOrder,
  ]);
  let parsedState = defaultState;
  try {
    parsedState = gridStateSchema.parse(initialState);
  } catch (err) {
    console.error("Failed to parse grid state", initialState);
  }

  const [historyStateRaw, setHistoryState] = useHistoryParam(keyParams.gridName, parsedState);
  const historyState = useMemo(() => {
    return mapGridStateOldIdsToNew(historyStateRaw, mapOldIdToNew);
  }, [historyStateRaw, mapOldIdToNew]);

  const onStateChange = useCallback(
    (state: GridStateFields) => {
      setHistoryState(state);
      setGridStateToSession(keyParams, state);

      const storedState = pick(state, [
        "sortModel",
        "density",
        "columnsOrder",
        "columnVisibilityModel",
        "pinnedColumns",
        "columnsWidths",
      ]);
      const correctedState = {
        ...storedState,
        columnVisibilityModel: {
          ...storedState.columnVisibilityModel,
          ...stateOverrides?.columnVisibilityModel,
        },
      };
      updateStoredState({ settings: correctedState });
    },
    [setHistoryState, keyParams, stateOverrides?.columnVisibilityModel, updateStoredState]
  );

  const stateWithSetters = useStateWithSetters({
    state: historyState!,
    setState: onStateChange,
    defaultState,
  });

  return (
    <GridStateContext.Provider
      value={{
        columns,
        state: stateWithSetters!,
        onStateChange,
        noDataFields,
      }}
    >
      {isLoading ? loader : children}
    </GridStateContext.Provider>
  );
}

interface KeyParams {
  gridName: UISettingsTableEntity;
  userId: string;
}

const gridSessionKey = ({ gridName, userId }: KeyParams) => `${gridName}_${userId}`;

const transformOldUITableSettingsToDataGridSettings = (settings: any): GridStateFields => {
  const columnVisibilityModel: Record<string, boolean> = {};
  settings.forEach((setting: any) => (columnVisibilityModel[setting.id] = setting.visible));

  return {
    columnVisibilityModel,
    columnsOrder: settings.map((setting: any) => setting.id),
    search: "",
    sortModel: [],
    density: "standard",
    pinnedColumns: { left: [], right: [] },
    columnsWidths: {},
    filters: {},
    pickedRow: undefined,
  };
};

const handleOldStyleTable = (
  uiSettings: ReturnType<typeof useUISettings<GridStateFields>>,
  keyParams: KeyParams
) => {
  const isNewTableVisibilityModel =
    uiSettings.data &&
    uiSettings.data.settings &&
    "columnVisibilityModel" in uiSettings.data.settings;

  const tableSettingsEmpty = !isNewTableVisibilityModel && uiSettings.data?.settings === undefined;

  if (tableSettingsEmpty && uiSettings.data) {
    uiSettings.data.settings = getDefaultState([]);
    return uiSettings;
  }

  if (
    Object.values(UISettingsTableEntity).includes(keyParams.gridName) &&
    !isNewTableVisibilityModel &&
    uiSettings.data &&
    Array.isArray(uiSettings.data.settings)
  ) {
    uiSettings.data.settings = transformOldUITableSettingsToDataGridSettings(
      uiSettings.data.settings
    );
    return uiSettings;
  }
  return undefined;
};

// Gets grid state stored in db
function useGridStoredState(keyParams: KeyParams) {
  const uiSettings = useUISettings<GridStateFields>({
    entityId: keyParams.gridName,
    userId: keyParams.userId,
  });

  const parsedOldStyle = handleOldStyleTable(cloneDeep(uiSettings), keyParams);

  return parsedOldStyle ?? uiSettings;
}

export function useUpdateGridStoredState(keyParams: KeyParams) {
  return useUpdateUISettings({ userId: keyParams.userId, entityId: keyParams.gridName });
}

// Gets grid state from session storage
function getGridStateFromSession(keyParams: KeyParams): GridStateFields | null {
  const stateString = sessionStorage.getItem(gridSessionKey(keyParams));

  try {
    return stateString ? JSON.parse(stateString) : null;
  } catch (err) {
    console.error(`Failed to load state from storage, key:${gridSessionKey(keyParams)}`);
    return null;
  }
}

function setGridStateToSession(keyParams: KeyParams, state: GridStateFields) {
  sessionStorage.setItem(gridSessionKey(keyParams), JSON.stringify(state));
}
