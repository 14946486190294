import React from "react";
import { Text } from "./Text";
import styles from "./Loader.module.css";

export interface LoaderProps {
  text?: string;
  detailsText?: string;
}

export const Loader = ({ text = "Loading", detailsText }: LoaderProps) => {
  return (
    <>
      <svg viewBox="0 0 96 96" fill="none" width="96" xmlns="http://www.w3.org/2000/svg">
        <g id="loading">
          {[
            { x: 16, delay: "0s" },
            { x: 42, delay: "0.15s" },
            { x: 68, delay: "0.25s" },
          ].map(({ x, delay }) => {
            return (
              <rect
                key={x}
                x={x}
                y="16"
                width="16"
                height="64"
                fill={"var(--interactive-positive"}
                className={styles.bar}
                style={{ "--delay": delay } as React.CSSProperties}
              />
            );
          })}
        </g>
      </svg>
      <Text className="mt-s text-center w-full" color="disabled" variant="tertiaryTitle">
        {text}
      </Text>
      {detailsText && (
        <Text className="mt-xs text-center" color="disabled" variant="bodyText">
          {detailsText}
        </Text>
      )}
    </>
  );
};
