import { IconSize } from "ds-ui/icons";
import { TextVariant } from "ds-ui/molecules/Text";
import { type Size } from "./types";

// TODO: deprecated this logic as we only have one size for the breadcrumbs
export type AvailableIconSize = Extract<IconSize, "m">;
export type DsToken = {
  iconSize: AvailableIconSize;
  fontSize: TextVariant;
  separatorSize: AvailableIconSize;
  lastItemFontSize: TextVariant;
};

const BREADCRUMBS_SIZE_TO_DS_TOKENS: Record<Size, DsToken> = {
  m: {
    iconSize: "m",
    separatorSize: "m",
    fontSize: "tertiaryTitle",
    lastItemFontSize: "secondaryTitle",
  },
};
export const getIconSize = (size: Size): AvailableIconSize =>
  BREADCRUMBS_SIZE_TO_DS_TOKENS[size].iconSize;
export const getTextVariant = (size: Size): TextVariant =>
  BREADCRUMBS_SIZE_TO_DS_TOKENS[size].fontSize;
export const getSeparatorSize = (size: Size): AvailableIconSize =>
  BREADCRUMBS_SIZE_TO_DS_TOKENS[size].separatorSize;
