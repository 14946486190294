import { DEFAULT_DATE_FORMAT } from "shared";
import { GridValidRowModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { GridCellParams } from "@mui/x-data-grid/models/params/gridCellParams";
import { GridCellClassNamePropType } from "@mui/x-data-grid/models/gridCellClass";
import { formatDateTimeZoned } from "libs/timezone/timezoneUtils";
import { format } from "libs/time";
import { isNullOrUndefined } from "libs/utils";
import { customGridClasses } from "./constants";

export const GRID_DEFAULT_EMPTY_LABEL = "–";

export const emptyCellFormatter =
  (emptyLabel = GRID_DEFAULT_EMPTY_LABEL) =>
  (params: GridValueFormatterParams) => {
    if (!params.value && params.value !== 0) {
      return emptyLabel;
    }

    return Array.isArray(params.value) ? params.value.join(", ") : params.value;
  };

export const numberFormatter =
  ({ locale = "en-US", suffix = "" }: { locale?: string; suffix?: string } = {}) =>
  (params: GridValueFormatterParams<number>) => {
    if (params.value === null || isNaN(Number(params.value))) {
      return GRID_DEFAULT_EMPTY_LABEL;
    }

    const valueFormatted = new Intl.NumberFormat(locale).format(Number(params.value));
    return suffix ? `${valueFormatted} ${suffix}` : valueFormatted;
  };

export const dateFormatter =
  ({
    timezone,
    dateFormat = DEFAULT_DATE_FORMAT,
  }: { timezone?: string; dateFormat?: string } = {}) =>
  (params: GridValueFormatterParams<Date>) => {
    if (isNullOrUndefined(params.value)) {
      return GRID_DEFAULT_EMPTY_LABEL;
    }

    return timezone
      ? formatDateTimeZoned(params.value, timezone, dateFormat)
      : format(params.value, dateFormat);
  };

export const booleanFormatter = () => (params: GridValueFormatterParams<number>) => {
  if (isNullOrUndefined(params.value)) {
    return GRID_DEFAULT_EMPTY_LABEL;
  }

  return params.value ? "Yes" : "No";
};

export const listFormatter =
  <T = any>({ getItemLabel = (v: T) => String(v) }: { getItemLabel?: (item: T) => string } = {}) =>
  (params: GridValueFormatterParams<T[]>) => {
    if (isNullOrUndefined(params.value) || !params.value.length) {
      return GRID_DEFAULT_EMPTY_LABEL;
    }

    return params.value.map(getItemLabel).join(", ");
  };

export const highlightCellClassName =
  <T extends GridValidRowModel>(condition: (value: T | undefined) => boolean) =>
  (params: GridCellParams<T>) => {
    return condition(params.value as T) ? customGridClasses.highlightCell : "";
  };

export const numberCellClassName =
  (cellClassName?: GridCellClassNamePropType) => (params: GridCellParams) => {
    const originalClassName =
      typeof cellClassName === "function" ? cellClassName(params) : cellClassName;

    if (params.value === 0) {
      return `${customGridClasses.zeroNumberCell}${
        originalClassName ? ` ${originalClassName}` : ""
      }`;
    }

    return originalClassName || "";
  };
