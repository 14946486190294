import React, { Fragment, useEffect, useRef, useState } from "react";
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import formatDuration from "date-fns/formatDuration";
import { useNavigate } from "react-router-dom";
import { Dialog, Button } from "@mui/material";
import { useModal, useSelector } from "libs/hooks";
import { Card, CardActions, space, Text } from "ui";
import { authState } from "../state";
import { messages } from "../messages";
import { logout } from "../effects";
import { useGlobalAuthSettings } from "../apiHooks";
import { defaultInactivityPromptTimeout, defaultInactivityTimeout } from "../constants";

interface UserInactivityTrackerProps {
  onMessage: (timer: IIdleTimer, message: string) => void;
  onIdle: (timer: IIdleTimer) => void;
  onStay: (timer: IIdleTimer) => void;
  timeout: number;
  promptTimeout: number;
}

const getRemainingTimeSeconds = (remainingTime: number) => {
  return Math.ceil(remainingTime / 1000);
};

function UserInactivityTracker({
  onStay,
  onIdle,
  onMessage,
  timeout,
  promptTimeout,
}: UserInactivityTrackerProps) {
  const timer = useIdleTimer({
    crossTab: true,
    syncTimers: 500,
    timeout,
    promptBeforeIdle: promptTimeout,
    onIdle: () => onIdle(timer),
    onPrompt: () => inactivityPopupApi.handleOpen(),
    onMessage: (message: string) => {
      inactivityPopupApi.handleClose();
      onMessage(timer, message);
    },
  });

  const [inactivityPopup, inactivityPopupApi] = useModal((args) => {
    const [remainingTime, setRemainingTime] = useState<string | undefined>();
    const formatTimeNum = (num: number) => String(num).padStart(2, "0");
    const remainingTimeMilliseconds = timer.getRemainingTime();

    const timeLabels = {
      xMinutes: "min",
      xSeconds: "s",
    };

    const updateRemainingTime = () => {
      const remainingSeconds = getRemainingTimeSeconds(timer.getRemainingTime());

      setRemainingTime(
        formatDuration(
          {
            minutes: remainingSeconds > 59 ? Math.floor(remainingSeconds / 60) : undefined,
            seconds: Math.floor(remainingSeconds % 60),
          },
          {
            zero: true,
            delimiter: " : ",
            format: ["minutes", "seconds"],
            locale: {
              formatDistance: (token, count) => {
                return `${formatTimeNum(count)} ${timeLabels[token as keyof typeof timeLabels]}`;
              },
            },
          }
        )
      );
    };

    useEffect(() => {
      if (!args.open) {
        return;
      }

      if (remainingTimeMilliseconds === 0 && args.open) {
        onIdle(timer);
      }

      if (remainingTimeMilliseconds > promptTimeout) {
        onStay(timer);
      }
    }, [remainingTimeMilliseconds, args.open]);

    useEffect(() => {
      if (args.open) {
        const interval = setInterval(() => {
          updateRemainingTime();
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      }

      return;
    }, [args.open]);

    return (
      <Dialog
        {...args}
        TransitionProps={{
          onEntering: updateRemainingTime,
        }}
        maxWidth="xs"
        fullWidth
        disableEscapeKeyDown
      >
        <Card style={{ padding: `${space["5"]} ${space["4"]}` }} space={0}>
          <Text variant="subtitle2" bold style={{ marginBottom: space["3"] }}>
            {messages.inactivityPopup.title}
          </Text>
          <Text variant="header5M" style={{ marginBottom: space["5"] }}>
            {remainingTime}
          </Text>
          <Text variant="body2" color="#737373" style={{ marginBottom: space["6"] }}>
            {messages.inactivityPopup.description}
          </Text>
          <CardActions>
            <Button
              onClick={() => {
                onIdle(timer);
                args.onClose();
              }}
            >
              {messages.inactivityPopup.logout}
            </Button>
            <Button
              onClick={() => {
                onStay(timer);
                args.onClose();
              }}
              variant="contained"
              color="primary"
            >
              {messages.inactivityPopup.stay}
            </Button>
          </CardActions>
        </Card>
      </Dialog>
    );
  }, true);

  return <Fragment>{inactivityPopup}</Fragment>;
}

const crossTabMessages = {
  logout: "LOGOUT_USER",
  stay: "STAY",
};

export function UserInactivityHandler() {
  const { id } = useSelector(authState.stores.user);
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGlobalAuthSettings({ enabled: Boolean(id) });
  // Save logging out state to handle multiple logout messages and prevent multiple logout flows start in parallel
  const isLoggingOutRef = useRef(false);

  if (!id || isLoading || isError) {
    return null;
  }

  const inactivityPromptTimeout =
    (data?.inactivity_prompt_timeout_seconds || defaultInactivityPromptTimeout) * 1000;
  const inactivityTimeout = (data?.inactivity_timeout_seconds || defaultInactivityTimeout) * 1000;

  const handleLogout = (timer: IIdleTimer) => {
    timer.message(crossTabMessages.logout, true);
  };

  const handleStay = (timer: IIdleTimer) => {
    timer.message(crossTabMessages.stay, true);
  };

  const handleMessage = async (timer: IIdleTimer, message: string) => {
    if (message === crossTabMessages.logout && !isLoggingOutRef.current) {
      isLoggingOutRef.current = true;
      await logout();
      navigate("/login");
      isLoggingOutRef.current = false;
    }

    if (message === crossTabMessages.stay) {
      timer.activate();
    }
  };

  return (
    <UserInactivityTracker
      timeout={inactivityTimeout}
      promptTimeout={inactivityPromptTimeout}
      onIdle={handleLogout}
      onStay={handleStay}
      onMessage={handleMessage}
    />
  );
}
