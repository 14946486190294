import React, { useLayoutEffect, useRef, useState } from "react";
import { resolveService } from "service";
import { useQuery } from "@tanstack/react-query";

export function useAuthService() {
  const { data, error } = useQuery({
    queryKey: ["AuthService"],
    queryFn: () => resolveService("authService"),
  });
  return {
    service: data,
    error,
  };
}

export function useCaptcha(deps?: any[], mount = true) {
  const [id, setState] = useState<string | null>(null);
  const { service } = useAuthService();
  const verifierInstance = useRef<firebase.default.auth.RecaptchaVerifier | null>(null);
  useLayoutEffect(() => {
    if (service && mount) {
      const id = Math.random().toString();
      setState(id);
      requestAnimationFrame(() => {
        verifierInstance.current = service?.loginMFASetupRecapcha(id);
      });
    }
  }, [service, mount, ...deps!]);

  return [
    <div
      key={id}
      id={id!}
      style={{ position: "absolute", width: 0, height: 0, whiteSpace: "nowrap" }}
    />,
    verifierInstance,
  ] as const;
}
