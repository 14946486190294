import { useCallback, useMemo, useState } from "react";
import constate from "constate";
import { useOnChange2 } from "libs/hooks/useOnChange2";
import { useGridState } from "./useGridState";
import { CHGridColDef, GridData, GridRowSelectionModel } from "./types";

// Contains all necessary data and methods to render a grid
// Unlike useGridState, it contains extra data and methods that are not persisted
export const [GridProvider, useGrid] = constate((): GridData => {
  const { state, columns, noDataFields } = useGridState();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>({
    selected: [],
    all: false,
  });

  const resetSelectionModel = useCallback(() => {
    setSelectionModel({ selected: [], all: false });
  }, []);

  const defaultColumnsOrder = useMemo(() => columns.map((col) => col.field), [columns]);
  const defaultColumnsSettings = useMemo(
    () =>
      columns
        .filter((column) => column.field !== "actions")
        .map((column) => ({
          id: column.field,
          visible: column.defaultVisible ?? false,
          name: column.headerName ?? "",
        })),
    [columns]
  );

  // Need to handle case when we have old order saved, but UI has new columns
  const finalColumnsOrder = useMemo<string[]>(() => {
    const columnsWithoutOrder = columns.filter(
      (column) => !state.columnsOrder.includes(column.field)
    );

    const newOrder = Array.from(new Set(state.columnsOrder)) as string[];
    columnsWithoutOrder.forEach((column) => {
      newOrder.splice(columns.indexOf(column) + 1, 0, column.field);
    });

    return newOrder;
  }, [state.columnsOrder, columns]);

  const getSortedColumnsWithWidths = useCallback(
    (inputCols: CHGridColDef[], columnsOrder = finalColumnsOrder) => {
      const columnsWithWidth = applyWidthsToColumns(inputCols, state.columnsWidths);
      return getSortedColumns(columnsWithWidth, columnsOrder);
    },
    [finalColumnsOrder, state.columnsWidths]
  );

  const [finalColumns, setFinalColumns] = useState<CHGridColDef[]>(() =>
    getSortedColumnsWithWidths(columns)
  );

  // Reset the column state when the columns change.
  useOnChange2(columns, () => {
    setFinalColumns(getSortedColumnsWithWidths(columns));
  });

  const setColumnsOrder = useCallback(
    (columnsOrder: string[]) => {
      state.setColumnsOrder(columnsOrder);
      setFinalColumns(getSortedColumnsWithWidths(columns, columnsOrder));
    },
    [state, getSortedColumnsWithWidths, columns]
  );

  const resetGrid = useCallback(() => {
    state.resetAll();
    setFinalColumns([...columns]);
    setSelectionModel({ selected: [], all: false });
  }, [columns, state]);

  const resetFilters = useCallback(() => {
    state.setFilters({});
  }, [state]);

  const resetColumnsWidths = useCallback(() => {
    state.resetColumnsWidths();
    setFinalColumns(getSortedColumns(columns, finalColumnsOrder));
  }, [state, columns, finalColumnsOrder]);

  const hasFilters = useMemo(() => Object.keys(state.filters).length > 0, [state.filters]);

  return {
    ...state,
    resetGrid,
    columns: finalColumns,
    columnsOrder: finalColumnsOrder,
    selectionModel,
    setSelectionModel,
    resetSelectionModel,
    setColumnsOrder,
    resetFilters,
    hasFilters,
    defaultColumnsOrder,
    defaultColumnsSettings,
    resetColumnsWidths,
    noDataFields,
  };
});

function getSortedColumns(columns: CHGridColDef[], columnsOrder: string[]) {
  return [...columns].sort((a, b) => columnsOrder.indexOf(a.field) - columnsOrder.indexOf(b.field));
}

function applyWidthsToColumns(columns: CHGridColDef[], columnsWidths: Record<string, number>) {
  return columns.map((column) =>
    columnsWidths[column.field]
      ? { ...column, width: columnsWidths[column.field], flex: undefined }
      : column
  );
}
