import React from "react";
import { PopoverProps } from "@mui/material";
import { usePopover } from "libs/hooks";
import { Menu } from "./Menu";
import { MenuItemProps } from "./MenuItem";

export type ActionsPopoverMenuProps = {
  popoverProps?: Partial<PopoverProps>;
  width?: number;
  children?: Array<React.ReactElement<MenuItemProps> | null>;
  renderTrigger: ({
    onClick,
    isOpen,
  }: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    isOpen: boolean;
  }) => React.ReactElement;
};

export function ActionsPopoverMenu({ children, renderTrigger }: ActionsPopoverMenuProps) {
  const [menu, actions] = usePopover((popoverApi) => (
    <Menu {...popoverApi}>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          // TODO: Check if these props are still used or not
          // isPopoverOpened: popoverApi.open,
          // onPopoverClose: popoverApi.onClose,
          onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            const props = child.props;
            props.onClick?.(e);

            if (props.closeOnClick) {
              actions.onClose();
            }
          },
        });
      })}
    </Menu>
  ));

  const handleClick = (e: React.MouseEvent) => {
    e?.stopPropagation?.();
    actions.onOpen(e.currentTarget);
  };

  return (
    <>
      {renderTrigger({ onClick: handleClick, isOpen: actions.open })}
      {menu}
    </>
  );
}
