import React, { Children } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { fixedForwardRef } from "shared";
import {
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from "@mui/material";
import styles from "./ToggleButtonGroup.module.css";
import { ButtonStyles } from "./Button";

const buttonGroupStyles = cva(
  "border-small border-medium border-solid rounded-s border-outlines-1",
  { variants: {} }
);

export type ToggleButtonGroupProps = Omit<
  MuiToggleButtonGroupProps,
  "disabled" | "size" | "color"
> &
  VariantProps<typeof ButtonStyles>;

export const ToggleButtonGroup = fixedForwardRef(function ToggleButtonGroup(
  { children, disabled, size, tone, weight, className, ...props }: ToggleButtonGroupProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <MuiToggleButtonGroup
      ref={ref}
      disabled={Boolean(disabled)}
      className={twMerge(buttonGroupStyles({ className }))}
      {...props}
    >
      {Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, {
          size,
          tone,
          disabled,
          weight,
          className: styles.ToggleButtonGroup__Button,
        });
      })}
    </MuiToggleButtonGroup>
  );
});
