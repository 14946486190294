import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import { SearchInput } from "ui";
import { css } from "ui/css";
import { useGrid } from "../useGrid";
import { gridColors } from "../theme";

const searchInputStyles = css({
  "width": "100%",
  "margin": 0,
  ".MuiInputBase-root": {
    background: "#fff",
    outline: "none !important",
  },
  "input": {
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
  },
});
export function GridToolbarSearch({ placeholder }: { placeholder?: string }) {
  const { search, setSearch } = useGrid();
  const [isSearching, setIsSearching] = useState(Boolean(search));

  return isSearching || Boolean(search) ? (
    <SearchInput
      autoFocus
      initialValue={search}
      className={searchInputStyles().className}
      placeholder={placeholder || "Search Name, Title, or Dept."}
      variant="outlined"
      inputProps={{
        startAdornment: null,
        onBlur: () => {
          if (!search) {
            setIsSearching(false);
          }
        },
      }}
      onCancel={() => {
        setSearch("");
        setIsSearching(false);
      }}
      onRequestSearch={(search: string) => (search.length > 2 || !search) && setSearch(search)}
    />
  ) : (
    <IconButton style={{ padding: 0 }} onClick={() => setIsSearching(true)}>
      <Search style={{ color: gridColors.searchIcon, opacity: 0.7 }} />
    </IconButton>
  );
}
