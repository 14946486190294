import React, { forwardRef } from "react";
import { Button, ButtonProps } from "@mui/material";
import { css } from "ui/css";
import { gridColors } from "../theme";

const gridButtonStyles = css({
  lineHeight: 1,
  borderRadius: "4px",
  textTransform: "capitalize",
  padding: "7px 10px",
  height: "36px",
  variants: {
    color: {
      primary: {
        "color": gridColors.primary,
        "borderColor": gridColors.primaryLight,
        "&:hover": {
          backgroundColor: gridColors.primaryBackground,
          borderColor: gridColors.primary,
        },
      },
      secondary: {
        "color": gridColors.main,
        "borderColor": gridColors.main,
        "opacity": 0.6,
        "&:hover": {
          borderColor: gridColors.main,
        },
      },
    },
  },
});

export const GridToolbarButton = forwardRef(function GridToolbarButton(
  props: ButtonProps,
  ref: any
) {
  return (
    <Button
      variant="outlined"
      ref={ref}
      className={
        gridButtonStyles({ color: (props.color as "primary" | "secondary") || "primary" }).className
      }
      {...props}
      size="medium"
    />
  );
});
