import React, { useEffect, useRef, useCallback, useContext, MutableRefObject } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { css } from "ui/css";
import ForkedEditor from "./SimpleCodeEditor.override";
import "prismjs/themes/prism-coy.css";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-java";
import "./LucenePrism";
import "./VarsPrism";
import { SuggesterUI } from "./Suggester";

type Props = Omit<
  JSX.LibraryManagedAttributes<typeof Editor, Editor["props"]>,
  "onValueChange" | "highlight"
>;

const Style = css({
  "& textarea": { outline: "none" },
  "fontSize": "13px",
  "minHeight": "34px",
  "height": "auto",
});

type ICodeEditorProps = Props & {
  className?: string;
  onChange?: (e: string) => any;
  language?: "lucene" | "java" | "xml";
  inputRef?: any;
  innerRef?: any;
  suggest?: boolean;
  options?: any;
};

export const SuggestContext = React.createContext<{
  suggester: any;
}>({ suggester: null });
function EditorImpl(
  {
    className,
    onChange,
    language,
    suggest,
    innerRef,
    inputRef,
    suggestConfig,
    SuggesterComponent = SuggesterUI,
    ...rest
  }: any,
  ref: any
) {
  const internalRef = useRef<HTMLTextAreaElement | null>(null);

  useKeypress(internalRef);
  const onRefChange = useCallback(
    (
      ref: React.Ref<any>,
      internalRef: React.MutableRefObject<HTMLTextAreaElement>,
      inputRef: any,
      editorRef: any
    ): React.LegacyRef<HTMLTextAreaElement> => {
      return (textareaRef) => {
        if (ref && (ref as any).current) {
          (ref as any).current = textareaRef;
        }
        internalRef.current = textareaRef!;
        inputRef && inputRef(textareaRef);
        editorRef(textareaRef);
      };
    },
    [inputRef, ref, internalRef]
  );
  suggestConfig = suggestConfig || useContext(SuggestContext);

  const F: any = ForkedEditor;
  return (
    <>
      <F
        {...rest}
        onValueChange={onChange}
        highlight={(code: any) => highlight(code, languages[language])}
        padding={10}
        ignoreTabKey={true}
        className={Style({ className }).className}
      >
        {({ ref: editorRef, onBlur, ...params }: any) => (
          <>
            <textarea
              // TODO
              onBlur={suggestConfig ? null : onBlur}
              ref={onRefChange(
                ref,
                internalRef as MutableRefObject<HTMLTextAreaElement>,
                inputRef,
                editorRef
              )}
              {...params}
              style={{ ...params.style, border: "none", whiteSpace: "break-spaces" }}
            />
            {suggest && suggestConfig && internalRef.current && (
              <SuggesterComponent
                textareaRef={internalRef as MutableRefObject<HTMLTextAreaElement>}
                onBlur={onBlur}
                suggester={suggestConfig.suggester}
              />
            )}
          </>
        )}
      </F>
    </>
  );
}

export const CodeEditor = React.forwardRef<any, ICodeEditorProps>(EditorImpl as any);

CodeEditor.defaultProps = {
  language: "lucene",
};

export const SuggestCodeEditor = React.forwardRef<any, ICodeEditorProps>(function SuggestCodeEditor(
  props: any,
  ref
) {
  return EditorImpl({ language: "lucene", ...props, suggest: true }, ref);
});

function useKeypress(internalRef: React.MutableRefObject<HTMLTextAreaElement | null>) {
  useEffect(() => {
    const item = internalRef.current!;
    function onType(e: KeyboardEvent) {
      if (e.shiftKey && e.which === 13) {
        e.preventDefault();
        item.blur();
      }
    }
    item.addEventListener("keydown", onType);
    return () => {
      item.removeEventListener("keydown", onType);
    };
  }, [internalRef]);
}
