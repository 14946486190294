import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";
import React, { Children, ForwardedRef, useEffect } from "react";
import debounce from "lodash/debounce";

export type ChildElements = Array<React.ReactNode | ((isScrolled: boolean) => React.ReactNode)>;
export type PageHeaderProps = {
  /*
   * Determine if the header should be sticky or not
   * It becomes sticky in the context of the container
   * TODO: Think if we need to have multiple modes, like fixed
   * */
  sticky?: boolean;
  /*
   * Determine if the header should display the compact or large variant (changes the y padding)
   * */
  compact?: boolean;
  /*
   * Display Left, Center and Right side elements
   */
  left?: ChildElements;
  right?: ChildElements;
  /*
   * Ref pointing to the main 'header' element
   * */
  ref?: ForwardedRef<HTMLDivElement>;
};

const genericHeaderVariants = cva("", {
  variants: {
    sticky: {
      true: "sticky top-0",
    },
    compact: {
      true: "py-2xl px-4xl h-[92px]",
      false: "p-4xl",
    },
  },
  defaultVariants: {
    sticky: false,
    compact: true,
  },
});
const themingClasses = "bg-background-base3 border-outlines-1 shadow-background-shadow";

export const PageHeader = React.memo(
  React.forwardRef(function PageHeader(
    { sticky, compact, left, right }: PageHeaderProps,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    const [isScrolled, setIsScrolled] = React.useState(true);

    const debouncedScrollHandler = React.useMemo(() => {
      return debounce(() => {
        window.scrollY > 10 ? setIsScrolled(true) : setIsScrolled(false);
      }, 50);
    }, []);

    // TODO: optimise the scroll behaviour to consider the container, not the window
    useEffect(() => {
      window.addEventListener("scroll", debouncedScrollHandler);
      return () => window.removeEventListener("scroll", debouncedScrollHandler);
    }, [debouncedScrollHandler]);

    const renderChildren = (children: ChildElements) => {
      return Children.map(children, (child) => {
        if (!child) {
          return null;
        }
        const childToRender = typeof child === "function" ? child(isScrolled) : child;
        return childToRender ? childToRender : null;
      });
    };

    return (
      <header
        className={twMerge(
          "w-full border-0 border-b border-solid flex justify-between gap-2xl transition-shadow z-10",
          themingClasses,
          genericHeaderVariants({ sticky, compact }),
          isScrolled && "shadow-md"
        )}
        ref={ref}
      >
        {left && Boolean(Children.count(left)) && (
          <div className="flex gap-xl pr-xs justify-self-start items-center justify-start flex-1 min-w-0">
            {renderChildren(left)}
          </div>
        )}
        {right && Boolean(Children.count(right)) && (
          <div className="flex gap-xl flex-shrink-0 justify-self-end items-center justify-end ">
            {renderChildren(right)}
          </div>
        )}
      </header>
    );
  })
);
