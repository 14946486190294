import React, { Suspense, useLayoutEffect } from "react";
import useMedia from "react-use/lib/useMedia";
import { ErrorBoundary } from "react-error-boundary";
import { reportError } from "shared/errors";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { authState } from "modules/auth";
import { Box, LayoutLoader } from "ui";
import { HStack } from "libs/layouts";
import { useSelector } from "libs/hooks";
import { Alert } from "ui/atoms/Alert";
import { FullscreenContent } from "../atoms";
import { Sidebar } from "./Sidebar";
import { messages } from "../messages";

function FallbackComponent({ error, failMessage = "Failed to load page" }: any) {
  useLayoutEffect(() => {
    reportError(error);
  }, [error]);
  return (
    <Box
      sx={{
        centerContent: true,
        height: "100%",
      }}
    >
      <Alert title={failMessage} icon={<CancelPresentationIcon />} />
    </Box>
  );
}

type LayoutClassicProps = {
  children: React.ReactNode;
  disableSmallScreens?: boolean;
};

export const LayoutClassic = React.forwardRef(function LayoutClassic(
  { children, disableSmallScreens = true }: LayoutClassicProps,
  ref: any
) {
  const user = useSelector(authState.stores.user);
  const isSmall = useMedia("(max-width: 1024px)");
  return (
    <HStack space={0} ref={ref} style={{ height: "100%" }}>
      {user.id && <Sidebar user={user} />}
      <FullscreenContent className="overflow-x-auto">
        {!isSmall || !disableSmallScreens ? (
          <ErrorBoundary fallbackRender={({ error }) => <FallbackComponent error={error} />}>
            <Suspense fallback={<LayoutLoader />}>{children}</Suspense>
          </ErrorBoundary>
        ) : (
          <Alert
            title={messages.notSupportedTitle}
            subtitle={messages.notSupportedSubtitle}
            icon={<CancelPresentationIcon />}
          />
        )}
      </FullscreenContent>
    </HStack>
  );
});
