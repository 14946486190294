// @ts-nocheck
export function overrideInsert() {
  const appendChildOriginal = Element.prototype.appendChild;
  Element.prototype.appendChild = function (...args) {
    const res = appendChildOriginal.apply(this, args);
    if (res.tagName === "STYLE") {
      res.classList.add("fs-css-in-js");
    }
    return res;
  };

  const insertBeforeOriginal = Element.prototype.insertBefore;
  Element.prototype.insertBefore = function (...args) {
    const res = insertBeforeOriginal.apply(this, args);
    if (res.tagName === "STYLE") {
      res.classList.add("fs-css-in-js");
    }
    return res;
  };
}
