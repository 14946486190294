import { PII_PLACEHOLDER } from "./constants";

export function hideTokenizedValue(value: string | undefined | null): string {
  if (!value) {
    return "";
  }

  if (/^token\[.+\]$/.test(value)) {
    return PII_PLACEHOLDER;
  }

  return value;
}
