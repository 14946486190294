// file with variables that are passed during run time
import { Features, initialFeatures } from "shared/features";
import { frontendconfig } from "api";

export type ENV = {
  CUSTOMER_CODE: string;
  STACKDRIVER_ERROR_REPORTER_API_KEY?: string;
  STACKDRIVER_PROJECT_ID?: string;
  ANALYTICS_API_KEY: string;
  INTERCOM_API_KEY: string;
  FULLSTORY_API_KEY: string;
  REACT_APP_STORY: string;
  IS_BETA_ENV: boolean;
  TOPLEVEL_DOMAIN: string;
  START_DATE: string;
  END_DATE: string;
  GIT_COMMIT_HASH: string;
  // derived short commit hash
  GIT_COMMIT_HASH_ID: string;
  GIT_COMMIT_DATE: number;
  GIT_COMMIT_MASTER_HASH: string;
  GIT_COMMIT_MASTER_DATE: number;
  GIT_COMMIT_DIRTY: boolean;
  GIT_TAG: string;
  VERSION: string;
  SSO_AUTH_URL: string;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  IS_INTERNAL: boolean;
  SUPERSET_URL: string;
  MUI_X_LICENSE_KEY: string;
  FEATURES: Features;
  // If it's true then all permissions are enabled, useful for debug purposes
  ALL_PERMISSIONS?: boolean;
};
// NOTE: initialized on preload phase in main.tsx, contains sensitive data
export const ENV_LOGGED = {
  CUSTOMER_NAME: "",
  APP_ANALYTICS_CUSTOMER_NAME: "",
};

export const updateSensitiveValues = (config: frontendconfig.FrontendConfig) => {
  ENV_LOGGED.CUSTOMER_NAME = config.CUSTOMER_NAME;
  ENV_LOGGED.APP_ANALYTICS_CUSTOMER_NAME = config.APP_ANALYTICS_CUSTOMER_NAME;
  return config;
};

function getBool(v: string | boolean | undefined, defaultValue: boolean = false) {
  if (v === "" || v === undefined) {
    return defaultValue;
  }

  return typeof v === "string" ? v === "true" : typeof v === "boolean" ? v : false;
}

// runtime env provided after build
const getEnv = (): ENV => {
  if (typeof window === "undefined") {
    return {} as any as ENV;
  }
  const versionInfo = JSON.parse(process.env.REACT_APP_VERSION || "{}");
  const isBeta = window.location.host.startsWith("beta") || process.env.NODE_ENV !== "production";
  const env = (window as any).ENV || {};
  const features = (window as any).FEATURES || {};
  const FIREBASE_AUTH_DOMAIN =
    (env.FIREBASE_AUTH_DOMAIN as string) || "es-scalability-test.firebaseapp.com";
  return {
    FIREBASE_API_KEY: "AIzaSyC8s2MQBZugyxVHx-G9FGemwLMG4R7wnm8",
    FIREBASE_AUTH_DOMAIN,
    ALL_PERMISSIONS: getBool(process.env.REACT_APP_ALL_PERMISSIONS),
    ...env,
    GIT_COMMIT_HASH: versionInfo.hash || env.GIT_COMMIT_HASH || "1",
    GIT_COMMIT_DATE: versionInfo.date * 1000 || env.GIT_COMMIT_DATE * 1000,
    GIT_COMMIT_MASTER_HASH: versionInfo.masterHash || env.GIT_COMMIT_MASTER_HASH,
    GIT_COMMIT_MASTER_DATE: versionInfo.masterDate * 1000 || env.GIT_COMMIT_MASTER_DATE * 1000,
    GIT_COMMIT_HASH_ID: versionInfo.hash?.slice(0, 9) || env.GIT_COMMIT_HASH?.slice(0, 9),
    GIT_COMMIT_DIRTY: Boolean(versionInfo.dirty || env.GIT_COMMIT_DIRTY),
    GIT_TAG: env.GIT_TAG || "dev-version",
    VERSION: env.VERSION || "1",
    CUSTOMER_CODE: env.CUSTOMER_CODE || "none",
    // if it's internal deployment without sensitive data
    IS_INTERNAL: FIREBASE_AUTH_DOMAIN.includes("es-scalability-test"),
    // api key for development
    // STACKDRIVER_ERROR_REPORTER_API_KEY: "AIzaSyC2f_wjB941CeGRvgkgh_8QsSMQtR-Svbs",
    // this is api key only for testing so it's fine to hardcode it.
    INTERCOM_API_KEY: env.INTERCOM_API_KEY || "uz5fl6w6",
    IS_BETA_ENV: isBeta,
    STACKDRIVER_PROJECT_ID: env.STACKDRIVER_PROJECT_ID || "es-scalability-test",
    TOPLEVEL_DOMAIN: env.TOPLEVEL_DOMAIN || "cyberhaven.io",
    REACT_APP_STORY: Boolean(process.env.REACT_APP_STORY),
    MUI_X_LICENSE_KEY:
      "55cee368202df72fb2a344bfdf22c941Tz0xMDU1NTQsRT0xNzY4MTc1OTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=",
    FEATURES: {
      ...initialFeatures,
      ...features,
    },
  };
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ENV = getEnv();
